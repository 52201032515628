<template>
    <div>
        <v-checkbox hide-details="false" class="py-0 my-0" dense :value="value" :label="type.fields.label.value" :disabled="true" ></v-checkbox>
    </div>
</template>
<script>
export default {
    props: ["val","type"],
    data() {
        return {
            value: false,
        };
    },
    mounted() {
        // console.log(this.val);
        this.value = this.val;
    }
}

</script>