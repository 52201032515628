<template>
  <div>
    <v-navigation-drawer app temporary v-model="drawer">
      <v-list dense>
        <v-subheader>REPORTS</v-subheader>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item v-for="(item, i) in menu_links" :key="i" :to="item.url">
            <v-list-item-icon>
              <v-icon dark>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      dark
      color="primary"
      elevation="0"
      app
      absolute
      class=""
      height="70px"
    >
      <div v-if="!user">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-md-and-up">
        </v-app-bar-nav-icon>
        <v-btn elevation="0" color="secondary" @click="goto('/register')">
          <span class="text-h6">להרשם</span>
          <!-- <v-icon >mdi-chevron-left</v-icon> -->
        </v-btn>
        <v-btn text dark @click="goto('/login')">
          <span class="text-h6">להתחבר</span>
        </v-btn>
      </div>
      <template v-if="user">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="logOut" text v-bind="attrs" v-on="on">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>יציאה</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="
                if (path.indexOf('/account') == -1)
                  $router.push({ name: 'MySpace' });
              "
              text
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <span>אזור אישי</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="messages" text v-bind="attrs" v-on="on">
              <v-badge
                overlap
                bordered
                color="green"
                :content="numOfUnread"
                :value="numOfUnread"
                left
              >
                <v-icon>mdi-message-text</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>הודעות</span>
        </v-tooltip>
      </template>
      <v-spacer></v-spacer>
      <!-- <v-toolbar-items class="hidden-sm-and-down mx-16">
        <v-btn
          :icon="item.title === ''"
          text
          v-for="(item, index) in menu_links"
          :key="index"
          :to="item.url"
          dark
        >
          <span class="text-h6">{{ item.title }}</span>
        </v-btn>
      </v-toolbar-items> -->
      <v-spacer></v-spacer>
      <!-- <RFormNewRecord
        v-if="!loading && user"
        :fields="lagguageHeaders"
        :values="values"
        @publishClick="publishClick"
        :buttonName="'פרסם מודעה'"
      /> -->
      <addRecord v-if="!loading && user" />
      <v-btn
        v-if="!loading && !user"
        class="mx-2"
        color="primary"
        elevation="0"
        @click="goto('/login')"
      >
        פרסם מודעה
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
      <v-btn color="primary" elevation="0" @click="goto('/search')">
        חפש מודעה
        <v-icon right>mdi-magnify</v-icon>
      </v-btn>
      <v-toolbar-title>
        <!-- <router-link :to="'/'" tag="button" class="pointer ml-16 pl-16"> -->

        <v-img
          @click="goto('/')"
          style="cursor: pointer"
          max-width="350px"
          src="@/assets/logo_go.png"
          contain
          max-height="40"
        ></v-img>
        <!-- </router-link> -->
      </v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
import fire from "@/main";

// import RFormNewRecord from "@/components/app/common/Form/RFormNewRecord/RFormNewRecord";
import addRecord from "@/components/addRecord/addRecord";

import { mapGetters } from "vuex";

export default {
  data: () => ({
    // loggedIn_2:null,
    loading: true,
    values: {},
    msgsToMe: [],
    numOfUnread: 0,
    drawer: false,
    selectedItem: 0,
    selectedItem_2: 0,
    menu_links: [
      { title: "מתענים", icon: "mdi-truck", url: "/luggage" },
      // { title: "הובלות", icon: "mdi-truck", url: "/trucking" },
      { title: "מעבר דירה", icon: "mdi-home-group", url: "/appartment" },
      { title: "אוטובוס", icon: "mdi-bus-side", url: "/bus" },
      { title: "דף הבית", icon: "", url: "/" },
    ],
  }),
  components: {
    // RFormNewRecord,
    addRecord,
  },
  methods: {
    async logOut() {
      await this.$store.dispatch("logOut");
      console.log(this.$route);
      console.log(this.user);
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
    goto(payload) {
      console.log("payload", payload);
      console.log("this.path", this.path);
      if (this.path !== payload) this.$router.push(payload);
    },
    messages() {
      if (this.$route.path !== "/MyChats") {
        this.$router.push("/MyChats");
      }
    },
    async publishClick(v) {
      // await this.$store.dispatch("loading", true);
      // this.addDialog = false;
      await this.$store.dispatch("AddLagguage", {
        val: v.val,
        target: v.target,
      });
      // await this.$store.dispatch("loading", false);
    },
  },

  // created(){
  // },
  computed: {
    path() {
      return this.$route.path;
    },
    ...mapGetters(["user", "UID", "lagguageHeaders"]),
  },
  async mounted() {
    await this.$store.dispatch("lagguageHeaders");
    this.loading = false;
    let viewMessage = this;
    if (this.user) {
      let itemsRef2 = await fire
        .database()
        .ref("messages")
        .orderByChild("to")
        .equalTo(this.UID);
      await itemsRef2.on("value", (snapshot) => {
        let data = snapshot.val();
        console.log("got new to...");
        if (data) {
          viewMessage.msgsToMe.length = 0;
          Object.keys(data).forEach((key) => {
            viewMessage.msgsToMe.push({
              id: key,
              from: data[key].from,
              to: data[key].to,
              unread: data[key].unread,
              msg_content: data[key].msg_content,
              timestamp: data[key].timestamp,
            });
          });
          let filtered = viewMessage.msgsToMe.filter((v) => {
            if (v.unread === true) {
              return v;
            }
          });
          viewMessage.numOfUnread = filtered.length;
        }
      });
    }
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
/* .v-toolbar {
  background: linear-gradient(90.12deg, #494b83 0.09%, #8587b5 99.89%);
  border-radius: 0px 0px 0px 209px !important;
} */
/* .v-btn--active{
  text-decoration: underline !important;
}
.v-btn--active::before{
  background-color: rgba(0, 0, 0, 0) !important;
}
.v-btn:hover::before {
    opacity: 0.0 !important;
}
.v-btn:hover::before {
    text-decoration: underline !important;
} */
</style>
