<template>
    <div class="mx-3 my-0 py-0">
        <v-card flat class="d-flex flex-wrap ">
            <div class="mx-1">
                <v-text-field clearable dense outlined :label="val.fields.label.value + ' מינימום'" type="number"
                    v-model="min" />
            </div>
            <div class="mx-1">
                <v-text-field clearable dense outlined :label="val.fields.label.value + ' מקסימום'" v-model="max" />
            </div>
            <div class="my-0 pb-7 mx-3 py-0">
                <v-divider vertical></v-divider>
            </div>
        </v-card>

    </div>
</template>
<script>
export default {
    props: ["val", "type"],
    data() {
        return {
            min: null,
            max: null
        };
    },
    mounted() {
        // console.log(this.val);
        // this.value = this.val;
    },
    watch: {
        min() {
            this.emitFilter();
        },
        max() {
            this.emitFilter();
        }
    },
    methods: {
        emitFilter() {
            this.$emit('update-filter', post => {
                if (this.min != null && this.min != '' && !post.values[`${this.val.id}`]) return false;
                if ((this.min === null || this.min === '') && !post.values[`${this.val.id}`]) return true;
                if (this.min != null && this.min != '' && post.values[`${this.val.id}`].value < this.min) return false;
                if (this.max != null && this.max != '' && post.values[`${this.val.id}`].value > this.max) return false;
                return true;
            });
        }
    }
}

</script>