<template>
  <div>
    <v-checkbox :value="internalValue" @change="updateValue" :label="fields.label.value"></v-checkbox>
  </div>
</template>

<script>
export default {
  name: "CheckBoxInput",
  props: ["clearFormFlag", "fieldName", "fields", "result", "showOptions"],
  data() {
    return {
      internalValue: null,
      label: "שם",  // временно статическое значение, пока мы не загружаем его из базы данных
    };
  },
  mounted() {
    // Инициализация internalValue значением result при монтировании
    if (this.result !== undefined) {
      // this.internalValue = this.result;
      this.updateValue(this.result);
    }
  },
  watch: {
    clearFormFlag(newVal) {
      if (newVal) {
        this.internalValue = false;
      }
    },
  },
  methods: {
    updateValue(newValue) {
      this.internalValue = newValue;
      this.$emit("input", { fieldName: this.fieldName, value: newValue, id:this.showOptions.id });
    },
  },
};
</script>
