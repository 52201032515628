<template>
  <div class="mx-3 my-0 py-0">
    <v-card flat class="d-flex flex-wrap ">
      <div class="my-0 py-0 mx-1">
        <div class="px-0 my-0 py-0 py-0"
          style="width:230px; border-radius: 5px; border-width: 10px; border: 1px solid darkgray;">
          <GmapAutocomplete @place_changed="setLocation" @input="updateCityInput"
            :placeholder="val.fields.placeholder.value" :options="autocompleteOptions" class="google-input-r" />
        </div>
      </div>
      <div class="px-0 my-0 py-0 mx-1">
        <v-text-field style="width:130px" dense outlined color="deep-orange lighten-2" label="רדיוס (קמ)" type="number"
          v-model="radius" />
      </div>
      <div class="my-0 pb-7 mx-3 py-0">
        <v-divider vertical></v-divider>
      </div>
    </v-card>
  </div>
</template>

<script>

export default {
  props: ["val", "type"],
  data() {
    return {
      googleLocation: null,
      location: null,
      radius: 0,
      autocompleteOptions: {
        types: ['(cities)'],
        fields: ['name', 'geometry', 'place_id'],
        componentRestrictions: { country: ['il', 'ps'] }
      }
    };
  },
  watch: {
    radius() {
      this.emitFilter();
    }
  },
  methods: {
    updateCityInput(event) {
      const input = event.target.value;
      if (!input || input === '') {
        this.location = null;
        this.emitFilter();
      }
    },
    setLocation(place) {
      console.log("setLocation");
      if (place && place.geometry) {
        this.googleLocation = place;
        this.location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
        this.emitFilter();
      }
    },
    emitFilter() {
      const radiusInKm = parseFloat(this.radius) || 0;
      this.$emit('update-filter', post => {
        if (!this.location) return true;
        if (!post.values[`${this.val.id}`]) return false;
        const postLat = post.values[`${this.val.id}`].value.geometry.lat;
        const postLng = post.values[`${this.val.id}`].value.geometry.lng;
        const distance = this.getDistanceFromLatLonInKm(this.location.lat, this.location.lng, postLat, postLng);
        // console.log("post.values[`${this.val.name}`]", post.values[`${this.val.name}`]);
        // console.log("this.location", this.location);
        // console.log("distance", distance);
        // console.log("radiusInKm", radiusInKm);
        // console.log("this.getCorrectShortName(post.values[`${this.val.name}`])", this.getCorrectShortName(post.values[`${this.val.name}`]));
        // console.log("this.getCorrectShortName(this.googleLocation)", this.getCorrectShortName(this.googleLocation));
        if (this.getCorrectShortName(post.values[`${this.val.id}`].value) == this.getCorrectShortName(this.googleLocation)) {
          return true;
        }
        return distance <= radiusInKm;
      });
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      var R = 6371;
      var dLat = this.deg2rad(lat2 - lat1);
      var dLon = this.deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    getCorrectShortName(place) {
      let city = null;
      let street_number = null;
      let route = null;
      let neighborhood = null;
      let point_of_interest = null;
      if (!place) return ''
      if (!place.address_components) {
        if (place.name) {
          return place.name
        }
        return "no name"
      }
      if (place.address_components && place.address_components.length == 0) {
        return place.name
      }

      for (let i = 0; i < place.address_components.length; i++) {
        let component = place.address_components[i];

        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (!city) {
          if (component.types.includes('administrative_area_level_2')) {
            city = component.long_name;
          }
        }
        if (component.types.includes('neighborhood')) {
          neighborhood = component.long_name;
        }
        if (component.types.includes('street_number')) {
          street_number = component.long_name;
        }
        if (component.types.includes('route')) {
          route = component.long_name;
        }
        if (component.types.includes('point_of_interest')) {
          point_of_interest = component.long_name;
        }
      }
      let showAdress = '';
      if (route) {
        showAdress += 'רח' + '\' ' + route;
      }
      if (street_number) {
        showAdress += ' ' + street_number;
      }
      if (route || street_number) {
        showAdress += ', '
      }
      if (city) {
        showAdress += city + ' ';
      }
      if (neighborhood) {
        showAdress += '(' + neighborhood + ')'
      }
      if (!place.buissness_name && point_of_interest) {
        place.buissness_name = point_of_interest
      }
      if (city != null) {
        return city
      } else if (place.buissness_name) {
        return `${place.buissness_name}`
      } else {
        return showAdress
      }
    },
  }
}
</script>
<style scoped>
::v-deep .gmap-autocomplete-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.pac-target-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.google-input-r {
  width: 200px;
  outline: none;
  border: none;
  box-shadow: none;
  margin: 3px 10px 3px 10px;
}
</style>