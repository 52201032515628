<template>
  <div>
    <div v-if="postTypesOptions.length > 0" class="my-16 mx-16">
      <v-card flat rounded="0" class="mx-0 px-16 my-16">
        <v-container>
          <v-row>
            <v-col v-for="typePost in postTypesOptions" :key="typePost.id" class="my-5">
              <v-card width="300px" style="height: 100%" class="d-flex flex-column">
                <v-card-title class="text-h5" color="primary">
                  {{ typePost.name }}
                  <v-spacer></v-spacer>
                  <v-icon color="primary" :style="{ 'font-size': '60px' }">mdi-{{ typePost.icon }}</v-icon>
                </v-card-title>
                <v-card-text>
                  {{ typePost.info }}
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="mb-3">
                  <v-btn outlined color="primary" class="px-7" @click="openNewPostDialog(typePost)">
                    אני צריך {{ typePost.name }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div v-if="openDialog">
      <addRecord :openDialog="openDialog" :startPostType="selectedPostType" @closeNewPostDialog="closeNewPostDialog"/>
    </div>

  </div>
</template>

<script>
import fire from "@/main";
import addRecord from "@/components/addRecord/addRecord";
export default {
  components: {
    addRecord,
  },
  data: () => ({
    postTypes: [],
    openDialog: false,
    selectedPostType: null,
    // loading: false,
  }),
  mounted() {
    this.getPostTypes();
  },
  computed: {
    postTypesOptions() {
      return this.postTypes.map(postType => ({
        name: postType.name,
        info: postType.info,
        value: postType.id,
        icon: postType.icon
      }));
    },
  },
  methods: {
    goto(payload) {
      if (this.path !== payload) this.$router.push(payload);
    },
    async getPostTypes() {
      let viewMessage = this;
      let itemRef = await fire.database().ref('admin/postTypes');
      await itemRef.on('value', async (snapshot) => {
        let items = snapshot.val();
        if (items) {
          let newState = [];
          for (let item in items) {
            if (items[item]) {
              newState.push({
                ...items[item],
                id: item,
                // fieldIds: items[item].fieldIds,
                name: items[item].name,
                postTypeExtended: items[item].postTypeExtended,
              });
            }
          }
          viewMessage.postTypes = newState;
          console.log('postTypes', viewMessage.postTypes)
        }
      });
    },
    openNewPostDialog(postType) {
      this.selectedPostType = postType;
      this.openDialog = true;
    },
    closeNewPostDialog() {
      this.openDialog = false;
      this.selectedPostType = null;
    },
  },
};
</script>

<style></style>