<template>
    <div>
        <div>
            <span class="text-h6">{{ type.fields.firs_date_label.value }}:</span><span> {{
                formatDateToDayView(value.dateFrom)
            }}</span>
        </div>
        <div v-if="value.dateTo">
            <span class="text-h6">{{ type.fields.second_date_label.value }}:</span><span> {{
                formatDateToDayView(value.dateTo)
            }}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: ["val", "type"],
    data() {
        return {
            value: "false",
        };
    },
    mounted() {
        // console.log('val',this.val);
        this.value = this.val;
    },
    methods: {
        formatDateToDayView(date) {
            if (date) {
                let dateObj = new Date(date);
                let day = dateObj.getDate();
                if (day < 10) {
                    day = "0" + day;
                }
                let month = dateObj.getMonth() + 1;
                if (month < 10) {
                    month = "0" + month;
                }
                let year = dateObj.getFullYear();
                return day + "-" + month + "-" + year;
            }
            return "";
        }
    }
}

</script>