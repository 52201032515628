import store from '@/store/index'

export default async function (to, from, next) {
    const isAuthenticated = await store.dispatch('getUID');
    const isAdminPath = to.path.startsWith('/admin');
    const expectedKey = '0532776648';
    const keyFromQuery = to.query.key;

    if (!isAuthenticated) {
        console.log('not logged in');
        console.log('to.path', to.path);
        if(to.path === '/forgot-password') {
            next();
            return;
        }
        if (to.path !== '/register' && to.path !== '/login') {
            next('/login');
            return;
        }
        if(to.path === '/register') {
            next();
            return;
        }
    }

    if (isAdminPath) {
        if (keyFromQuery !== expectedKey) {
            next('/');
            return;
        }

        if (to.path === '/admin' || to.path === '/admin/') {
            next({ path: '/admin/NewPostComponents', query: to.query });
            return;
        }
    }

    if (to.path === '/account') {
        next('/account/MySpace');
        return;
    }

    // if (to.path === '/register') {
    //     next('/register');
    //     return;
    // }

    next();
}
