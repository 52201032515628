// import firebase from 'firebase/compat/app';

export default {
    state: {
        loading: false,
        error: null,
        success: null,
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        clearError(state) {
            state.error = null
        },
        setSuccess(state, payload) {
            state.success = payload
        },
        clearSuccess(state) {
            state.success = null
        },
    },
    actions: {
        loading (context, payload) {
            context.commit('setLoading',payload)
        },
        setError (context, payload) {
            context.commit('setError',payload)
        },
        clearError (context) {
            context.commit('clearError')
        },
        setSuccess (context, payload) {
            console.log(payload+" - from store");
            context.commit('setSuccess',payload)
        },
        clearSuccess (context) {
            context.commit('clearSuccess')
        },
    },
    getters: {
        loading(state) {
            return state.loading
        },
        error(state) {
            return state.error
        },
        success(state) {
            return state.success
        },
    }
}