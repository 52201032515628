<!-- ProposalComponent.vue -->
<template>
    <!---
    //q: какие могут быть альтернативы если я не хочу это все обворачивать в див? (в родительском компоненте кнопка активарор диалогового окна должна быть в одной строке с другими кнопками)
    //a: можно использовать фрагменты, но они не работают везде, например в директивах v-if, v-else, v-for
    //a: можно использовать тег template, но он не работает везде, например в директивах v-if, v-else, v-for
    //a: можно использовать компонент v-container, но он добавляет дополнительные стили
    //a: можно использовать компонент v-row, но он добавляет дополнительные стили
    //a: можно использовать компонент v-col, но он добавляет дополнительные стили
    //a: можно использовать компонент v-spacer, но он добавляет дополнительные стили
    //a: можно использовать компонент v-layout, но он добавляет дополнительные стили
    //a: можно использовать компонент v-flex, но он добавляет дополнительные стили
    //a: можно использовать компонент v-app, но он добавляет дополнительные стили

    --->
    <span>
        <v-btn color="primary" v-if="!myProposal && UID && !isOwner" @click="showProposalPopup">לתת הצעת
            מחיר</v-btn>
        <v-btn color="primary" v-if="myProposal && UID && !isOwner" @click="showProposalPopup">עדכן הצעת
            מחיר</v-btn>
        <v-btn color="primary" v-if="isOwner && UID" @click="allProposalsPopup = true">הצעות מחיר ({{
            proposalsForThisPost.length }})</v-btn>

        <v-dialog v-model="proposalPopup" max-width="600px">
            <v-card>
                <v-card-title>הצעת מחיר</v-card-title>
                <v-card-text>
                    <v-text-field type="number" label="הצעת מחיר" v-model="proposalPrice"></v-text-field>
                    <v-textarea label="הערות" v-model="proposalComments"></v-textarea>
                    <div v-if="myProposal">
                        <div>
                            <span class="text-h6">תאריך מתן ההצעה:</span> <span>{{
            formatDateToDayView(myProposal.created)
        }}
                            </span>
                        </div>
                        <div>
                            <span class="text-h6">תאריך השינוי האחרון:</span> <span>{{
                formatDateToDayView(myProposal.lastUpdated) }}
                            </span>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" v-if="!myProposal" @click="sendProposal">לשלוח הצעת מחיר</v-btn>
                    <v-btn color="primary" v-if="myProposal" @click="updateProposal">לעדכן הצעת מחיר</v-btn>
                    <v-btn color="primary" @click="proposalPopup = false">ביטול</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="allProposalsPopup" max-width="600px">
            <v-card>
                <v-card-title>הצעות מחיר</v-card-title>
                <v-card-text>
                    <v-card v-for="proposal in proposalsForThisPost" :key="proposal.id" class="mb-3" outlined>
                        <v-card-text>
                            <div v-if="proposal.status === 'accepted' || proposal.status === 'done'"
                                class="floating-icon">
                                <v-icon class="floating-icon-mdi">mdi-check</v-icon>
                            </div>
                            <div v-if="proposal.status === 'rejected'" class="floating-icon">
                                <v-icon class="floating-icon-mdi-rejected">mdi-close</v-icon>
                            </div>
                            <div v-if="proposal.price">
                                <span class="text-h6">מחיר:</span> <span>{{ proposal.price }}₪</span>
                            </div>
                            <div v-else>
                                <span class="text-h6">מחיר:</span> <span>0₪</span>
                            </div>
                            <div v-if="proposal.comments">
                                <span class="text-h6">הערות:</span> <span>{{ proposal.comments }}</span>
                            </div>
                            <div v-else>
                                <span class="text-h6">הערות:</span> <span><i class="grey--text">אין
                                        הערות</i></span>
                            </div>
                            <div v-if="proposal.created">
                                <span class="text-h6">תאריך מתן ההצעה:</span> <span>{{
            formatDateToDayView(proposal.created)
        }}
                                </span>
                            </div>
                            <div v-if="proposal.lastUpdated">
                                <span class="text-h6">תאריך השינוי האחרון:</span> <span>{{
            formatDateToDayView(proposal.lastUpdated) }}
                                </span>
                            </div>
                            <div v-if="proposal.proposalerUID">
                                <span class="text-h6">מציע:</span>
                                <!-- {{ proposal.proposalerUID }} -->
                                <userInfo :uid="proposal.proposalerUID" />
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <div v-if="proposal.status">
                                <v-select :items="computedProposalStates(proposal)" v-model="proposal.status" dense
                                    @change="updateProposalStatus(proposal)" outlined></v-select>
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="allProposalsPopup = false">סגור</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>

import fire from "@/main";

import userInfo from "../info/userInfo.vue";

export default {
    props: ['post', 'UID', 'postId'],
    data() {
        return {
            proposalPopup: false,
            allProposalsPopup: false,
            myProposal: null,
            proposalsForThisPost: [],
            proposalPrice: 0,
            proposalComments: "",
            proposalStates: [{
                text: "פעיל",
                value: "active"
            },
            {
                text: "התקבל",
                value: "accepted"
            },
            {
                text: "נדחה",
                value: "rejected"
            },
            {
                text: "בוצעה",
                value: "done"
            }
            ]
        }
    },
    components: {
        userInfo
    },
    computed: {
        isOwner() {
            console.log("this.UID", this.UID);
            console.log("this.post", this.post);
            console.log("this.post.uid", this.post.uid);

            if (!this.UID) return false;
            if (!this.post || !this.post.uid) return false
            if (this.UID === this.post.uid) return true
            return false
        },
        isOneProposalAcceptedOrDone() {
            if (this.proposalsForThisPost) {
                let acceptedOrDone = this.proposalsForThisPost.filter(proposal => proposal.status === "accepted" || proposal.status === "done");
                if (acceptedOrDone.length > 0) {
                    return true;
                }
            }
            return false;
        },


    },
    mounted() {
        this.getProposalsForThisPost();
    },
    methods: {
        ifThisProposalAcceptedOrDone(proposal) {
            if (proposal.status === "accepted" || proposal.status === "done") {
                return true;
            }
            return false;
        },
        computedProposalStates(proposal) {
            if (this.ifThisProposalAcceptedOrDone(proposal)) {
                return this.proposalStates;
            }
            if (!this.isOneProposalAcceptedOrDone) {
                return this.proposalStates;
            } else {
                return this.proposalStates.filter(state => state.value === "rejected" || state.value === "active");
            }
        },
        formatDateToDayView(date) {
            if (date) {
                let dateObj = new Date(date);
                //add 0 before day if it is less than 10
                let day = dateObj.getDate();
                if (day < 10) {
                    day = "0" + day;
                }
                //add 0 before month if it is less than 10
                let month = dateObj.getMonth() + 1;
                if (month < 10) {
                    month = "0" + month;
                }
                let year = dateObj.getFullYear();
                return day + "-" + month + "-" + year;
            }
            return "";
        },
        showProposalPopup() {
            this.proposalPopup = true;
            if (this.myProposal) {
                this.proposalPrice = this.myProposal.price;
                this.proposalComments = this.myProposal.comments;
            } else {
                let startPriceField = this.post.values["-NnjmzmDsABlG01REmF5"];
                if (startPriceField) {
                    this.proposalPrice = startPriceField.value;
                } else {
                    this.proposalPrice = 0
                }
                this.proposalComments = "";
            }
        },
        async getProposalsForThisPost() {
            let viewMessage = this;
            let itemRef = await fire.database().ref('admin/proposals').orderByChild("postId").equalTo(this.postId);
            await itemRef.on('value', async (snapshot) => {
                let items = snapshot.val();
                if (items) {
                    // console.log("this.post", items);
                    viewMessage.proposalsForThisPost = Object.keys(items).map(key => {
                        return {
                            ...items[key], // все свойства объекта
                            id: key          // добавляем id
                        };
                    });
                    console.log("this.proposalsForThisPost", this.proposalsForThisPost);
                    if (this.UID && this.proposalsForThisPost) {
                        this.myProposal = this.proposalsForThisPost.find(proposal => proposal.proposalerUID === this.UID);
                        if (this.myProposal) {
                            this.proposalPrice = this.myProposal.price;
                            this.proposalComments = this.myProposal.comments;
                        }
                        // this.proposalPrice = this.myProposal.price;
                        // this.proposalComments = this.myProposal.comments;
                    }
                }
            });
        },
        async sendProposal() {
            if (!this.UID) {
                alert("Please login to send proposal");
                return;
            }
            let proposal = {
                postId: this.postId,
                price: this.proposalPrice,
                comments: this.proposalComments,
                status: "active",
                proposalerUID: this.UID,
                created: new Date().toISOString(),
                lastUpdated: new Date().toISOString(),
                postCreatorUID: this.post.uid
            }
            await fire.database().ref("admin/proposals").push(proposal);
            await this.sendMassageAboutProposal("newProposal", proposal, proposal.postCreatorUID);
            this.proposalPopup = false;
        },

        async updateProposal() {
            if (!this.UID) {
                alert("Please login to send proposal");
                return;
            }
            let proposal = {
                postId: this.postId,
                price: this.proposalPrice,
                comments: this.proposalComments,
                proposalerUID: this.UID,
                lastUpdated: new Date().toISOString(),
                postCreatorUID: this.post.uid
            }
            await fire.database().ref("admin/proposals").child(this.myProposal.id).update(proposal);
            await this.sendMassageAboutProposal("updateProposal", proposal, proposal.postCreatorUID);
            this.proposalPopup = false;
        },
        async updateProposalStatus(proposal) {
            await fire.database().ref("admin/proposals").child(proposal.id).update({
                status: proposal.status
            });
            await this.sendMassageAboutProposal(proposal.status, proposal, proposal.proposalerUID);
        },
        async sendMassageAboutProposal(type, proposal, to) {
            let msg_obj = {
                from: this.UID,
                timestamp: new Date().toISOString(),
                to: to,
                unread: true,
            }
            if (type === "accepted") {
                msg_obj.msg_content = "הצעתך התקבלה";
            } else if (type === "rejected") {
                msg_obj.msg_content = "הצעתך נדחתה";
            } else if (type === "updateProposal") {
                msg_obj.msg_content = "ההצעה עודכנה";
                if (proposal.price) {
                    msg_obj.msg_content += "</br> מחיר: " + proposal.price + "₪";
                }
                if (proposal.comments) {
                    msg_obj.msg_content += "</br> הערות: " + proposal.comments;
                }
            } else if (type === "done") {
                msg_obj.msg_content = "הצעתך בוצעה";
            } else if (type === "newProposal") {
                msg_obj.msg_content = "התקבלה הצעת מחיר חדשה";
                if (proposal.price) {
                    msg_obj.msg_content += "</br> מחיר: " + proposal.price + "₪";
                }
                if (proposal.comments) {
                    msg_obj.msg_content += "</br> הערות: " + proposal.comments;
                }
            }
            //add link to post /posts/[post id] to msg_obj.msg_content, use html link
            msg_obj.msg_content += "</br>";
            msg_obj.msg_content += " לפרטים נוספים לחץ כאן";
            msg_obj.msg_content = msg_obj.msg_content.replace("לחץ כאן", "<a href='/posts/" + proposal.postId + "'>לחץ כאן</a>");
            await fire.database().ref("messages").push(msg_obj);
        }
    }
}
</script>

<style scoped>
.floating-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 100;
}

.floating-icon-mdi {
    color: green;
    font-size: 3rem;
    font-weight: 600;
    text-shadow: 2px 2px 5px #505050cb;
}

.floating-icon-mdi-rejected {
    color: red;
    font-size: 3rem;
    font-weight: 600;
    text-shadow: 2px 2px 5px #505050cb;
}
</style>