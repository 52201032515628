<template>
  <v-row class="my-3 py-0" align="center" justify="center">
    <v-col cols="1" class="my-0 py-0">
      <v-icon :disabled="singleElement ? true : false" @click="remove" color="red">mdi-close</v-icon>
    </v-col>
    <v-col cols="3" class="my-0 py-0">
      <v-select dense v-if="furnitures" v-model="selectedFurnituresType" :items="itemsLevel1" :label="label_1"
        item-text="id" item-value="id" return-object single-line outlined style="max-height: 36px" @change="emitUpdate()"
        clearable></v-select>
    </v-col>
    <v-col cols="3" class="my-0 py-0">
      <v-select dense v-model="selectedFurniture" :items="itemsLevel2" :label="label_2" return-object single-line outlined
        style="max-height: 36px" @change="emitUpdate()" :disabled="!selectedFurnituresType" clearable></v-select>
    </v-col>
    <oneFurnitureRowNodal :labels="actions" :title="'אופציות נוספות'" @submit="submit" :enable="selectedFurniture"
      :result="this.actionsNeeded" />
    <v-col cols="2" class="my-0 py-0">
      <span class="float-left">
        <v-icon :disabled="!selectedFurniture" @click=addItems color="green">mdi-plus</v-icon>
        {{ items }}
        <v-icon :disabled="items === 1 ? true : false" @click="removeItems" color="red">mdi-minus</v-icon>
      </span>
    </v-col>
  </v-row>
</template>


<script>
import oneFurnitureRowNodal from "./oneFurnitureRowNodal.vue";
export default {
  name: "oneFurnitureRow",
  props: {
    furnitures: null,
    index: null,
    currentIndex: null,
    actions: {
      required: true,
    },
    label_1: {
      required: true,
    },
    label_2: {
      required: true,
    },
    singleElement: {
      default: false,
    },
    modelValue: {
      default: () => ({}),
    },
    result: {
      type: Object,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      dialog: false,
      selectedFurnituresType: null,
      selectedFurniture: null,
      selected: [],
      items: 1,
      actionsNeeded: [],
    };
  },
  computed: {
    itemsLevel1() {
      let items = this.furnitures;
      return Object.keys(items);
    },
    itemsLevel2() {
      if (this.selectedFurnituresType) {
        let items = this.furnitures;
        if (items[this.selectedFurnituresType] && items[this.selectedFurnituresType].length > 0) {
          return items[this.selectedFurnituresType];
        }
        return [];
      }
      return [];
    },
  },
  created() {
    this.modelValue.selectedFurnituresType = null;
    this.modelValue.selectedFurniture = null;
  },
  mounted() {
    if (this.result && this.result.type && this.result.item) {
      // console.log("this.result: !!! ", this.result);
      this.selectedFurnituresType = this.result.type;
      this.selectedFurniture = this.result.item;
      if (this.result.actionsNeeded) {
        this.actionsNeeded = this.result.actionsNeeded
      } else {
        this.actionsNeeded = []
      }
      if (this.result.items) {
        this.items = this.result.items;
      } else {
        this.items = 1;
      }
      this.emitUpdate();
    }
  },
  methods: {
    submit(payload) {
      this.actionsNeeded = payload
      this.emitUpdate()
    },
    remove() {
      this.$emit("remove", this.index);
    },
    addItems() {
      this.items = this.items + 1;
      this.emitUpdate();
    },
    removeItems() {
      if (this.items > 1) {
        this.items = this.items - 1;
        this.emitUpdate();
      }
    },
    emitUpdate() {
      // console.log(this.modelValue);
      let v = {}
      v.currentIndex = this.currentIndex
      v.selectedFurnituresType = this.selectedFurnituresType
      v.selectedFurniture = this.selectedFurniture
      v.items = this.items
      v.actionsNeeded = this.actionsNeeded
      this.$emit("update:modelValue", v);
    },
  },
  components: {
    oneFurnitureRowNodal,
  },
  watch: {
    modelValue() {
      console.log(this.modelValue);
    },
  },
};
</script>

<style></style>