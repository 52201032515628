<template>
    <div>
        <v-btn v-if="uid!==UID" color="primary" @click="dialog = true">השאר ביקורת</v-btn>

        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">השאר ביקורת</span>
                </v-card-title>

                <v-card-text>
                    <v-form ref="form">
                        <v-rating v-model="review.rating" background-color="yellow darken-3" color="yellow"></v-rating>

                        <v-textarea v-model="review.comment" label="תגובה" auto-grow clearable></v-textarea>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">ביטול</v-btn>
                    <v-btn color="blue darken-1" text @click="submitReview">שלח</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";

export default {
    name: "FeedbackDialog",
    props: {
        uid: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dialog: false,
            review: {
                rating: 0,
                comment: ""
            },
            fromUserInfo: {},
            fromName: "",
        };
    },
    computed: {
        ...mapGetters(["user", "UID"]),
    },
    created() {
        this.getFromUserInfo();
        },
    methods: {
        async submitReview() {
            if (this.review.rating > 0 && this.review.comment) {
                this.review.uid = this.uid;
                this.review.from = this.UID;
                this.review.createdAt = new Date().toISOString();
                this.review.fromName = this.getFromName();
                try {
                    this.dialog = false;
                    await fire.database().ref('reviews').push(this.review);
                    this.$refs.form.reset();

                    this.$store.dispatch("setSuccess", "הביקורת נשלחה בהצלחה");
                } catch (error) {
                    this.$store.dispatch("setError", "שגיאה בשליחת הביקורת");
                    console.error("Error adding review: ", error);
                }
            } else {
                this.$toast.warning("אנא מלא את כל השדות");
            }
        },
        async getFromUserInfo(){
            let viewMessage = this;
            let itemRef = await fire.database().ref('users/' + this.UID);
            itemRef.on('value', function (snapshot) {
                viewMessage.fromUserInfo = snapshot.val();
            });
        },
        getFromName(){
            if (this.fromUserInfo.companyInfo && this.fromUserInfo.companyInfo.companyName && this.fromUserInfo.companyInfo.companyName != '') {
                return "חברת \"" + this.fromUserInfo.companyInfo.companyName + "\"";
            } else if (this.fromUserInfo.info && this.fromUserInfo.info.name) {
                return this.fromUserInfo.info.name;
            } else {
                return 'אין שם';
            }
        }
    }
};
</script>

<style>
/* הוסף סגנונות במידת הצורך */
</style>
