import firebase from 'firebase/compat/app';
import { sortByMixin } from '@/mixins/tables/sortByVuexMixin.js';
var sortBy = sortByMixin;

export default {
    state: {
        NewPosts: null,
        Archives: null,
        Tamples: null,
        inProgress: null,
        done: null,
    },
    mutations: {
        NewPosts(state, payload) {
            state.NewPosts = payload
        },
        Archives(state, payload) {
            state.Archives = payload
        },
        Tamples(state, payload) {
            state.Tamples = payload
        },
        inProgress(state, payload) {
            state.inProgress = payload
        },
        done(state, payload) {
            state.done = payload
        },
    },
    actions: {
        async GetLagguageNewPosts(context, payload) {
            if (!context.getters['LagguageNewPosts']) {
                let uid = null;
                let myPorposals = null;
                if (!payload.allUsers) {
                    uid = await context.dispatch('getUID');
                    if (payload.trucking) {
                        myPorposals = await context.dispatch('getProposalByPproposalerID', { proposalerUID: uid })
                        console.log({ myPorposals });
                    }
                }
                let NewPosts = await context.dispatch('downloadLagguageNewPosts',
                    {
                        uid: uid,
                        target: payload.target,
                        myPorposals: myPorposals,
                        trucking: payload.trucking
                    }
                )
                await context.commit(payload.target, NewPosts)
            }
            return context.getters[payload.target]
        },
        async FetchLagguageNewPosts(context, payload) {
            const uid = await context.dispatch('getUID');
            let NewPosts = await context.dispatch('downloadLagguageNewPosts', { uid: uid, target: payload.target })
            await context.commit(payload.target, NewPosts)
            return context.getters[payload.target]
        },
        async AddLagguage(context, payload) {
            let pushedPostRef = await firebase.database().ref(`recors/lagguage/${payload.target}`).push(
                payload.val
            )
            let postId = pushedPostRef.getKey();
            console.log("postId", postId);
            await firebase.database().ref(`recors/lagguage/${payload.target}/${postId}`).update({ id: postId })
            await context.dispatch('FetchLagguageNewPosts', { target: payload.target })
        },
        async downloadLagguageNewPosts(context, payload) {
            let NewPosts = null
            if (payload.uid) {
                if (payload.trucking) {
                    // let NewPosts={}
                    if (payload.myPorposals) {
                        for await (let porposal of payload.myPorposals) {
                            // const porposal = payload.myPorposals[index];
                            let postUID = porposal.postUID;
                            let onePost = null;
                            let res = (await firebase.database().ref(`recors/lagguage/${payload.target}`).orderByChild("id").equalTo(postUID).once('value')).val()
                            if (res) {
                                console.log({ res1: res });
                                // res.porposal=porposal
                                onePost = res
                            } else {
                                // console.log({ postUID:porposal.postUID });
                                let innerPost = (await firebase.database().ref(`recors/lagguage/${payload.target}/${postUID}`).once('value')).val()
                                if (innerPost) {
                                    console.log({ res2: innerPost });
                                    // innerPost.porposal=porposal
                                    onePost = {};
                                    onePost = { ...onePost }
                                    onePost[postUID] = innerPost;
                                }
                            }
                            if (onePost) {
                                // console.log(onePost);
                                if (!NewPosts) {
                                    NewPosts = {}
                                }
                                NewPosts = { ...NewPosts, ...onePost }
                            }
                        }
                    }
                    // payload.myPorposals.forEach(async (porposal) => {
                    //     let postUID = porposal.postUID;
                    //     let onePost = null;
                    //     let res = (await firebase.database().ref(`recors/lagguage/${payload.target}`).orderByChild("id").equalTo(postUID).once('value')).val()
                    //     if (res) {
                    //         onePost = res
                    //     } else {
                    //         // console.log({ postUID:porposal.postUID });
                    //         let innerPost = (await firebase.database().ref(`recors/lagguage/${payload.target}/${postUID}`).once('value')).val()
                    //         if (innerPost) {
                    //             onePost = {};
                    //             onePost = { ...onePost }
                    //             onePost[postUID] = innerPost;
                    //         }
                    //     }
                    //     if (onePost) {
                    //         console.log(onePost);
                    //         if (!NewPosts) {
                    //             NewPosts = {}
                    //         }
                    //         NewPosts = { ...NewPosts, ...onePost }
                    //     }
                    // });
                    // console.log({ NewPosts });
                } else {
                    NewPosts = (await firebase.database().ref(`recors/lagguage/${payload.target}`).orderByChild("uid").equalTo(payload.uid).once('value')).val()
                    console.log({ NewPosts });
                }
            } else {
                NewPosts = (await firebase.database().ref(`recors/lagguage/${payload.target}`).once('value')).val()
            }
            if (NewPosts) {
                console.log("NewPosts is NOT empty");
                NewPosts = Object.keys(NewPosts).map(key => ({ id: key, ...NewPosts[key], currentID: key, postState: payload.target }))
            } else {
                NewPosts = []
                console.log("NewPosts is empty");
            }
            NewPosts = sortBy(NewPosts, {
                prop: "postDate",
                desc: true,
                parser: function (item) { return new Date(item); }
            })
            NewPosts = sortBy(NewPosts, {
                prop: "startDate",
                desc: true,
                parser: function (item) { return new Date(item); }
            })
            if (!payload.uid) {
                let nowDate = new Date(Date.now() - 86400000)
                NewPosts = NewPosts.filter(v => {
                    let startDate = new Date(v.startDate)
                    if (+startDate >= +nowDate) {
                        return v
                    }
                })
            }
            return NewPosts || []
        },
        async deleteLagguage(context, payload) {
            await firebase.database().ref(`recors/lagguage/${payload.sourse}/${payload.val.currentID}`).remove()
        },
        async relocateLagguage(context, payload) {
            await context.dispatch('deleteLagguage', payload)
            await context.dispatch('AddLagguage', payload)
        },
        async updateLagguage(context, payload) {
            await firebase.database().ref(`recors/lagguage/${payload.postState}`).child(payload.currentID).update(payload)
            await context.dispatch('FetchLagguageNewPosts', { target: payload.postState })
        }
    },
    getters: {
        NewPosts(state) {
            return state.NewPosts
        },
        Archives(state) {
            return state.Archives
        },
        Tamples(state) {
            return state.Tamples
        },
        inProgress(state) {
            return state.inProgress
        },
        done(state) {
            return state.done
        },
    }
}