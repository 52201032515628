<template>
    <div class="mx-1 my-0 py-0 ">

        <v-card class="d-flex flex-wrap " flat>

            <div class="ml-8 my-0 py-0 position-relative">
                <span v-if="showOptions.required" class="required-star">*</span>
                <v-card flat width="230px ">

                    <v-menu v-model="menuFrom" :close-on-content-click="true" :nativesync="true"
                        transition="scale-transition" color="deep-orange lighten-2">
                        <template v-slot:activator="{ on, attrs }">

                            <v-text-field color="deep-orange lighten-2" dense outlined :value="dateFromFormatted"
                                :label="fields.firs_date_label.value" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                                v-on="on" class="py-0" readonly clearable></v-text-field>
                        </template>
                        <v-date-picker v-model="dateFrom" :min="today" no-title lang="he" locale="he-IL"></v-date-picker>
                    </v-menu>
                </v-card>
            </div>
            <div class="mx-8 my-0 py-0" style="width:230px">
                <v-checkbox dense v-model="isRange" :label="fields.main_label.value" @change="isRangeChanged"></v-checkbox>
            </div>
            <div class="my-0 py-0 mr-8">
                <v-card flat width="230px">
                    <v-menu v-model="menuTo" :close-on-content-click="true" :nativesync="true" transition="scale-transition"
                        :disabled="!isRange || !dateFrom">
                        <template v-slot:activator="{ on, attrs }" :disabled="!isRange || !dateFrom">
                            <v-text-field :value="dateToFormatted" color="deep-orange lighten-2" dense outlined
                                :label="fields.second_date_label.value" prepend-inner-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on" class="py-0" clearable
                                :disabled="!isRange || !dateFrom"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateTo" :min="dateFrom" no-title lang="he" locale="he-IL"></v-date-picker>
                    </v-menu>
                </v-card>
            </div>
        </v-card>
    </div>
</template>

<script>
import { format } from 'date-fns'

export default {
    name: 'DateRangeInput',
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
        modelValue: {
            type: Object,
            default: () => ({}),
        },
        clearFormFlag: {
            type: Boolean,
            default: false
        },
        fieldName: {
            type: String,
            default: ''
        },
        result: {
            type: Object,
            default: () => ({})
        },
        showOptions: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        menuFrom: false,
        menuTo: false,
        dateFrom: null,
        dateTo: null,
        isRange: false,
    }),
    computed: {
        today() {
            return new Date().toISOString().substr(0, 10);
        },
        dateFromFormatted() {
            return this.dateFrom ? format(new Date(this.dateFrom), 'dd-MM-yyyy') : ''
        },
        dateToFormatted() {
            return this.dateTo ? format(new Date(this.dateTo), 'dd-MM-yyyy') : ''
        },
    },
    methods: {
        getFulldata(date) {
            if (!date) return null;
            return new Date(date).toISOString();
        },
        isRangeChanged() {
            if (!this.isRange) {
                this.dateTo = null;
            }
        }
    },
    mounted() {
        if (this.result && this.result.dateFrom) {
            // console.log("this.result", this.result);
            this.dateFrom = this.result.dateFrom;
            if (this.result.dateTo) {
                this.dateTo = this.result.dateTo;
                this.isRange = true;
            }
        }
    },
    watch: {
        dateFrom(val) {
            this.$emit('input', { fieldName: this.fieldName, value: { dateFrom: this.getFulldata(val), dateTo: this.getFulldata(this.dateTo) }, id: this.showOptions.id });
        },
        dateTo(val) {
            this.$emit('input', { fieldName: this.fieldName, value: { dateFrom: this.getFulldata(this.dateFrom), dateTo: this.getFulldata(val) }, id: this.showOptions.id });
        },
        clearFormFlag(newVal) {
            if (newVal) {
                this.dateFrom = null;
                this.dateTo = null;
                this.isRange = false;
            }
        }
    },
};
</script>
<style scoped>
.position-relative {
    position: relative;
}

.required-star {
    position: absolute;
    top: 0px;
    /* Настройте эти значения в соответствии с вашим дизайном */
    right: -10px;
    color: red;
    font-size: large;
    /* Или другой размер, который вам нужен */
}
</style>