<template>
  <v-app>
    <v-card flat width="400" class="mx-auto mt-5" :loading="loading">
      <v-card-title class="pb-0 justify-center">
        <h1 class="my-16">להרשם</h1>
      </v-card-title>
      <v-card-text v-if="!loading">
        <v-form>
          <v-text-field
            v-model="email"
            prepend-icon="mdi-account"
            :type="'email'"
            filled
            rounded
            dense
            single-line
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>דואר
            </template>
          </v-text-field>
          <v-text-field
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            filled
            rounded
            dense
            single-line
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>סיסמה
            </template>
          </v-text-field>
          <!-- <v-text-field
            v-model="phoneNumber"
            :type="'text'"
            filled
            rounded
            dense
            single-line
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>טלפון
            </template>
          </v-text-field>
          <v-text-field
            v-model="name"
            :type="'text'"
            filled
            rounded
            dense
            single-line
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>שם
            </template>
          </v-text-field> -->
          <!-- <v-checkbox v-model="bussiness" :label="`חברה`"> </v-checkbox> -->
          <v-radio-group v-model="bussiness" mandatory>
            <v-radio label="חשבון פרטי" :value="false"></v-radio>
            <v-radio label="חברה" :value="true"></v-radio>
          </v-radio-group>
          <!-- <v-text-field
            v-model="bussinesNumber"
            :type="'number'"
            filled
            rounded
            dense
            single-line
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>תעודת זהות
            </template>
          </v-text-field>
          <v-select
            v-if="userRoles !== null"
            :items="userRoles"
            v-model="userRole"
            filled
            rounded
            dense
            single-line
            ><template #label>
              <span class="red--text"><strong>* </strong></span>תפקיד
            </template></v-select
          >
          <v-text-field
            v-model="secondEmail"
            label="מייל עסקי"
            :type="'email'"
            filled
            rounded
            dense
            single-line
          /> -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn dark color="secondary" class="px-13" @click="registerUser"
          >הרשם</v-btn
        >
        <span class="mr-3 mx-1"> כבר רשום? </span>
        <router-link :to="'/login'"> התחבר</router-link>
        <!-- <v-btn @click="login" color="info">Login</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      showPassword: false,
      email: "",
      password: "",
      name: "",
      userRole: "",
      bussinesNumber: null,
      secondEmail: "",
      phoneNumber: "",
      bussiness: false,
    };
  },
  methods: {
    async registerUser() {
      try {
        let msg = {
          email: this.email,
          password: this.password,
          name: this.name,
          userRole: this.userRole,
          bussinesNumber: this.bussinesNumber,
          secondEmail: this.secondEmail,
          phoneNumber: this.phoneNumber,
          bussiness: this.bussiness,
        };
        if (this.bussiness === true) {
          msg.showCars = true;
          msg.showContactPersons = true;
        }
        await this.$store.dispatch("registerUser", msg);
        this.$router.push("/account");
        // console.log("msg", msg);
      } catch (error) {
        () => {};
      }
    },
    async login() {
      try {
        this.$router.push("/login");
        // await this.$store.dispatch("login", {
        //   email: this.email,
        //   password: this.password,

        // });
        // this.$router.push("/");
      } catch (error) {
        () => {};
      }
    },
    async getUserRoles() {
      this.$store.dispatch("loading", true);
      await this.$store.dispatch("getUserRoles");
      this.$store.dispatch("loading", false);
    },
  },
  created() {
    this.getUserRoles();
  },
  computed: {
    ...mapGetters(["loading", "userRoles"]),
  },
};
</script>