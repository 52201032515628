<template>
    <div>
        <span class="text-h6">{{ type.fields.label.value }}:</span><span> {{ joinedValues }}</span>
    </div>
</template>
<script>
export default {
    props: ["val", "type"],
    data() {
        return {
            value: null,
        };
    },
    mounted() {
        // console.log(this.val);
        this.value = this.val;
    },
    computed: {
        joinedValues() {
            if (this.value === null) {
                return "";
            }

            // console.log(this.value);
            // Предполагается, что у вас есть массив значений в вашем состоянии данных
            // Например, это может быть this.myArray
            // let arr = JSON.parse(this.value);
            return this.value.join(", ");
        }
    }
}

</script>