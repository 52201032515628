<template>
  <div class="mx-1 my-0 py-0 position-relative">
    <v-card flat width="230px">
      <span v-if="showOptions.required" class="required-star">*</span>
      <v-select color="deep-orange lighten-2" dense outlined :value="internalValue" @change="updateValue"
        :label="fields.label.value" :items="items"></v-select>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SelectOneInput",
  props: ["clearFormFlag", "fieldName", "fields", "result", "showOptions"],
  data() {
    return {
      internalValue: "false",
      label: "שם",  // временно статическое значение, пока мы не загружаем его из базы данных
    };
  },
  computed: {
    items() {
      return this.fields.options.value.split(",");
    }
  },
  mounted() {
    if (this.result && this.items.includes(this.result)) {
      this.updateValue(this.result);
    }
  },
  watch: {
    clearFormFlag(newVal) {
      if (newVal) {
        this.internalValue = false;
      }
    },
  },
  methods: {
    updateValue(newValue) {
      this.internalValue = newValue;
      this.$emit("input", { fieldName: this.fieldName, value: newValue, id:this.showOptions.id  });
    },
  },
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.required-star {
  position: absolute;
  top: 0px;
  /* Настройте эти значения в соответствии с вашим дизайном */
  right: -10px;
  color: red;
  font-size: large;
  /* Или другой размер, который вам нужен */
}
</style>