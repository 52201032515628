<template>
    <div>
        <div>
            <span class="text-h6">{{ type.fields.title.value }}:</span>
            <div v-for="rec, index in valuesToShow" :key="index">
                <div v-if="rec.value">
                    <v-icon> mdi-circle-small </v-icon> <span class="text-subtitle-1">{{ rec.value.selectedFurniture }} - {{
                        getCorrectYehidotWritten(rec) }} </span> <span class="text-subtitle-1"> {{ getActionsNeeded(rec)
    }} </span>
                </div>
            </div>
        </div>
        <!-- <span> {{ value }}</span> -->
    </div>
</template>
<script>
export default {
    props: ["val", "type"],
    data() {
        return {
            value: null,
        };
    },
    mounted() {
        // console.log(this.val);
        this.value = this.val;
    },
    computed: {
        valuesToShow() {
            if (!this.value) return []
            let values = []
            for (let i = 0; i < this.value.length; i++) {
                const element = this.value[i];
                if (element.value) {
                    values.push(element)
                }
            }
            return values
        }
    },
    methods: {
        getActionsNeeded(record) {
            let actionsNeeded = " - "
            if (record && record.value && record.value.actionsNeeded) {
                let scopeAdded = false;
                // console.log("record.value.actionsNeeded", record.value.actionsNeeded);
                //go through all the actions needed in record.value.actionsNeeded, if its checked value is true, add its label to actionsNeeded and ad a comma
                for (let i = 0; i < record.value.actionsNeeded.length; i++) {
                    const action = record.value.actionsNeeded[i];
                    if (action.checked) {
                        if (!scopeAdded) {
                            actionsNeeded += "("
                            scopeAdded = true
                        }
                        actionsNeeded += action.label + ", "
                    }
                }
                //remove the last comma
                actionsNeeded = actionsNeeded.slice(0, -2)
                if (scopeAdded) {
                    actionsNeeded += ")"
                }
            }
            return actionsNeeded
        },
        getCorrectYehidotWritten(rec) {
            let ret = rec.value.items;
            ret += " "
            if (rec.value.items === 1) {
                ret += "יחידה"
            } else {
                ret += "יחידות"
            }
            return ret
        }
    },
};

</script>