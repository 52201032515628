<template>
  <div class="mx-1 my-0 py-0 position-relative">
    <v-card flat width="230px">
      <span v-if="showOptions.required" class="required-star">*</span>
      <v-text-field :value="internalValue" @input="updateValue" :label="fields.label.value" type="number" :min="minValue"
        :max="maxValue" dense outlined color="deep-orange lighten-2"></v-text-field>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "NumberInput",
  props: ["clearFormFlag", "fieldName", "fields", "result", "showOptions"],
  data() {
    return {
      internalValue: 0,
    };
  },
  computed: {
    minValue() {
      return this.fields.min && this.fields.min.value ? this.fields.min.value : null;
    },
    maxValue() {
      return this.fields.max && this.fields.max.value ? this.fields.max.value : null;
    }
  },
  mounted() {
    if (this.result !== undefined) {
      this.updateValue(this.result);
    }
  },
  watch: {
    clearFormFlag(newVal) {
      if (newVal) {
        this.internalValue = 0;
      }
    },
  },
  methods: {
    updateValue(newValue) {
      const numericValue = Number(newValue);

      // Проверяем, есть ли у нас установленные min и max
      const hasMin = this.minValue !== null;
      const hasMax = this.maxValue !== null;

      if (
        (!hasMin || numericValue >= this.minValue) &&
        (!hasMax || numericValue <= this.maxValue)
      ) {
        this.internalValue = numericValue;
      } else {
        this.internalValue = 0;
        // Если вы хотите выполнить какие-либо дополнительные действия, когда значение выходит за пределы, добавьте их здесь.
      }

      this.$emit("input", { fieldName: this.fieldName, value: this.internalValue, id:this.showOptions.id  });
    }
  },
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.required-star {
  position: absolute;
  top: 0px;
  /* Настройте эти значения в соответствии с вашим дизайном */
  right: -10px;
  color: red;
  font-size: large;
  /* Или другой размер, который вам нужен */
}
</style>
