<template>
    <div>
        <span class="text-h6">{{ type.fields.placeholder.value }}: </span>
        <span>
            <a :href="`https://www.google.com/maps/search/?api=1&query=${getCorrectName(value)}&query_place_id=${value.place_id}`"
                target="_blank">
                {{ getCorrectName(value) }}
            </a>
        </span>
    </div>
</template>
<script>
export default {
    props: ["val", "type"],
    data() {
        return {
            value: "false",
        };
    },
    mounted() {
        // console.log("this.val", this.val);
        this.value = this.val;
    },
    methods: {
        getCorrectName(place) {
            // console.log("place in show one field", place);
            let city = null;
            let street_number = null;
            let route = null;
            let neighborhood = null;
            let point_of_interest = null;
            if (!place) return ''
            if (!place.address_components) {
                if (place.name) {
                    return place.name
                }
                return "no name"
            }
            if (place.address_components && place.address_components.length == 0) {
                return place.name
            }

            for (let i = 0; i < place.address_components.length; i++) {
                let component = place.address_components[i];

                if (component.types.includes('locality')) {
                    city = component.long_name;
                }
                if (!city) {
                    if (component.types.includes('administrative_area_level_2')) {
                        city = component.long_name;
                    }
                }
                if (component.types.includes('neighborhood')) {
                    neighborhood = component.long_name;
                }
                if (component.types.includes('street_number')) {
                    street_number = component.long_name;
                }
                if (component.types.includes('route')) {
                    route = component.long_name;
                }
                if (component.types.includes('point_of_interest')) {
                    point_of_interest = component.long_name;
                }
            }
            let showAdress = '';
            if (route) {
                showAdress += 'רח' + '\' ' + route;
            }
            if (street_number) {
                showAdress += ' ' + street_number;
            }
            if (route || street_number) {
                showAdress += ', '
            }
            if (city) {
                showAdress += city + ' ';
            }
            if (neighborhood) {
                showAdress += '(' + neighborhood + ')'
            }
            if (!place.buissness_name && point_of_interest) {
                place.buissness_name = point_of_interest
            }
            if (place.buissness_name) {
                return `${showAdress} (${place.buissness_name})`
            } else if (place.fullName) {
                return showAdress
            } else {
                return showAdress
            }
        },
    }
}

</script>