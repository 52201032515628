<template>
  <div>
    <Header />
    <v-card flat class="mx-auto my-12" max-width="90vw">
      <!-- <v-progress-linear
        color="indigo"
        height="10"
        indeterminate
        v-if="loading"
      >
      </v-progress-linear> -->

      <showFilteredPosts />
      <AddPost />
      <!-- <NewPosts :allUsers="true" :previewMode="true" /> -->

    </v-card>
  </div>
</template>

<script>
import fire from "@/main";
// import { mapGetters } from "vuex";

// import NewPosts from "@/components/app/MyLuggage/pages/NewPosts.vue";
import Header from "@/components/app/Home/topSearchPost.vue";
import AddPost from "@/components/app/Home/bottomAddPost.vue";
import showFilteredPosts from '@/components/showRecord/showFilteredPosts.vue'
export default {
  name: "Luggage",
  data: () => ({
    metaData: [],
  }),
  components: {
    // NewPosts,
    Header,
    AddPost,
    showFilteredPosts,
  },

  async created() {
    this.getMetaData();
    // await this.$store.dispatch("loading", true);
    // await this.$store.dispatch("lagguageHeaders");
    // await this.$store.dispatch("loading", false);
  },
  computed: {
    // ...mapGetters(["loading"]),
  },
  methods: {
    async getMetaData() {
      let viewMessage = this;
      let itemRef = await fire.database().ref('admin/metaData/home');
      await itemRef.on('value', async (snapshot) => {
        let items = snapshot.val();
        if (items) {
          
          viewMessage.metaData = items;
          console.log('metaData', viewMessage.metaData)
        }
      });
    },
  },
  metaInfo() {
    return {
      title: this.metaData.title || 'go24.co.il',
      meta: [
        {
          author: this.metaData.author || 'go24',
          description: this.metaData.description || 'go24.co.il',
          keywords: this.metaData.keywords || 'go24.co.il',
          viewport: this.metaData.viewport || 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no',
          robots: this.metaData.robots || 'index, follow',
          canonical: this.metaData.canonical || 'go24.co.il',
          referrer: this.metaData.referrer || 'go24.co.il',
        }
      ]
    }
  }
};
</script>
