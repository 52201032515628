import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGuard from './auth-guard'
import Home from '../views/Home.vue'
import Account from '../views/Account.vue'
import Register from '../views/Register.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'go24', description: 'go24 - это международная платформа для поиска и заказа грузо перевозок, а также услуг по хранению и перевозке багажа.' }
  },
  {
    path: '/info/:pageName',
    name: 'DinamicInfoPage',
    component: () => import('@/views/DinamicInfoPage.vue'),
    props: true
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('@/views/About.vue'),
  // },
  // {
  //   path: '/FAQ',
  //   name: 'FAQ',
  //   component: () => import('@/views/FAQ.vue'),
  // },
  // {
  //   path: '/contacts',
  //   name: 'Contacts',
  //   component: () => import('@/views/Contacts.vue'),
  // },
  {
    path: '/luggage',
    name: 'luggage',
    component: () => import('@/views/Luggage.vue'),
    meta: { title: 'go24' }
  },
  {
    path: '/search/:search',
    name: 'search',
    component: () => import('@/views/Search.vue'),
    props: true
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/Search.vue'),
  },
  {
    path: '/posts/:id',
    name: 'PostDetail',
    component: () => import('@/views/PostDetail.vue'),
    props: true
  },
  {
    path: '/companyInfoPageDynamic/:id',
    name: 'companyInfoPageDynamic',
    component: () => import('@/views/companyInfoPageDynamic.vue'),
    props: true
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/admin/Admin.vue'),
    beforeEnter: AuthGuard,
    children: [
      {
        path: '',
        component: () => import('@/views/admin/NewPostComponents.vue'),
        beforeEnter: AuthGuard
      },
      {
        path: 'NewPostComponents',
        name: 'NewPostComponents',
        component: () => import('@/views/admin/NewPostComponents.vue'),
        beforeEnter: AuthGuard
      },
      {
        path: 'NewPostTypes',
        name: 'NewPostTypes',
        component: () => import('@/views/admin/NewPostTypes.vue'),
        beforeEnter: AuthGuard
      },
      {
        path: 'MainPageHeaders',
        name: 'MainPageHeaders',
        component: () => import('@/views/admin/MainPageHeaders.vue'),
        beforeEnter: AuthGuard
      },
      {
        path: 'MetaData',
        name: 'MetaData',
        component: () => import('@/views/admin/MetaData.vue'),
        beforeEnter: AuthGuard
      },
      {
        path: 'infoPages',
        name: 'infoPages',
        component: () => import('@/views/admin/infoPages.vue'),
        beforeEnter: AuthGuard
      }
      ,
      {
        //Images
        path: 'Images',
        name: 'Images',
        component: () => import('@/views/admin/Images.vue'),
      }
    ]
  },
  {
    path: '/account',
    // name: 'account',
    component: Account,
    meta: { title: 'go24 | account' },
    redirect: { name: 'MySpace' },
    beforeEnter: AuthGuard,
    children: [
      // {
      //   path: '',
      //   name: 'MySpace',
      //   component: MySpace,
      //   meta: { title: 'go24' },
      //   beforeEnter: AuthGuard
      // },
      {
        path: '',
        component: () => import('@/views/account/MySpace.vue'),
        meta: { title: 'go24' },
        beforeEnter: AuthGuard
      },
      {
        path: 'MySpace',
        name: 'MySpace',
        component: () => import('@/views/account/MySpace.vue'),
        meta: { title: 'go24' },
        beforeEnter: AuthGuard
      },
      {
        path: 'MyLuggage',
        name: 'MyLuggage',
        component: () => import('@/views/account/MyLuggage.vue'),
        meta: { title: 'go24' },
        beforeEnter: AuthGuard
      },
      {
        path: 'MyLuggage2',
        name: 'MyLuggage2',
        component: () => import('@/views/account/MyLuggage2.vue'),
        meta: { title: 'go24' },
        beforeEnter: AuthGuard
      },
      {
        path: 'MyStaredPage',
        name: 'MyStaredPage',
        component: () => import('@/views/account/MyStaredPage.vue'),
        beforeEnter: AuthGuard
      },
      {
        path: 'MyTrucking',
        name: 'MyTrucking',
        component: () => import('@/views/account/MyTrucking.vue'),
        meta: { title: 'go24' },
        beforeEnter: AuthGuard
      },
      {
        path: 'MyChats',
        name: 'MyChats',
        component: () => import('@/views/account/MyChats.vue'),
        meta: { title: 'go24' },
        beforeEnter: AuthGuard
      }
    ]
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { title: 'go24' },
    beforeEnter: AuthGuard
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: { title: 'go24' },
    beforeEnter: AuthGuard
  },
  {
    path: '/forgot-password',
    name: 'ForgetPassword',
    component: () => import('@/views/ForgetPassword.vue'),
    meta: { title: 'go24' },
    beforeEnter: AuthGuard
  },
  {
    path: '/MyChats',
    name: 'MyChats',
    component: () => import('@/views/account/MyChats.vue'),
    meta: { title: 'go24' },
    beforeEnter: AuthGuard
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import('@/views/VerifyEmail.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  // baseUrl:'',
  base: process.env.BASE_URL,
  // base: '',
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
