<template>
  <div class="mx-1 my-0 py-0">
    <v-card dense flat width="230px" class="position-relative">
      <span v-if="showOptions.required" class="required-star">*</span>
      <div class="px-3 my-0 py-0 border" style=" border-radius: 5px; border-width: 10px;border: 1px solid darkgray;">
        <GmapAutocomplete @place_changed="handlePlaceChanged" :value="placeForm" :options="options"
          :placeholder="fields.placeholder.value" class="google-input-r" />
      </div>
    </v-card>
  </div>
</template>
  
<script>
/* global google */
import axios from 'axios';

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    clearFormFlag: Boolean,
    fieldName: String,
    result: Object,
    showOptions: {
      type: Object,
      default: () => ({id:"99"})
    },
    // добавьте другие props, которые вы хотели бы принять, здесь
  },
  data: () => ({
    value: null,
    placeForm: null,
    placeholder: " 2הזן כתובת",
    map: null,
    service: null,
  }),
  async mounted() {
    await this.loadGoogleMapsScript();
    if (this.result) {
      let resultLocal = JSON.parse(JSON.stringify(this.result));
      this.handlePlaceChanged(resultLocal);
    }
  },
  created() {
    // console.log('created' + this.placeholder);
  },
  computed: {
    options() {
      return {
        fields: ['name', 'geometry', 'place_id', 'address_components', 'business_status'],
        componentRestrictions: {
          country: ['il', 'ps']
        }
      }
    }
  },
  methods: {
    async handlePlaceChanged(placeSourse) {
      let place = JSON.parse(JSON.stringify(placeSourse));
      if (!place || !place.address_components || !place.address_components.length) {
        this.placeForm = null
        // this.$emit('place-changed', null);
        return;
      }
      let city = null;
      let street_number = "";
      let route = "";
      let buissness_name = null;

      for (let i = 0; i < place.address_components.length; i++) {
        let component = place.address_components[i];

        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('street_number')) {
          street_number = component.long_name;
        }
        if (component.types.includes('route')) {
          route = component.long_name;
        }
        if (component.types.includes('point_of_interest')) {
          buissness_name = component.long_name;
        }
      }

      this.placeForm = `${city}`
      if (route) {
        this.placeForm += `, ${route}`
      }
      if (street_number) {
        this.placeForm += ` ${street_number}`
      }
      if (buissness_name && !place.business_status) {
        this.placeForm += `, ${buissness_name}`
      }
      //if no place.geometry.location than try to take it from lat and lng straight
      if (!place.geometry.location) {
        place.geometry = { lat: place.geometry.lat, lng: place.geometry.lng }
      } else {
        //check if place.geometry.location is a function
        if (typeof place.geometry.location.lat === 'function') {
          place.geometry = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
        } else {
          place.geometry = { lat: place.geometry.location.lat, lng: place.geometry.location.lng }
        }
        // place.geometry = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
      }
      if (place.business_status) {
        var request = {
          placeId: place.place_id,
          fields: ['name']
        };

        if (!this.map || !this.service) {
          await this.loadGoogleMapsScript();
          this.service.getDetails(request, (placeResult, status) => {
            if (status == google.maps.places.PlacesServiceStatus.OK) {
              buissness_name = placeResult.name;
              this.placeForm = `${buissness_name}`
              place.buissness_name = buissness_name
            }
          });

        } else {
          this.map = new google.maps.Map(document.createElement('div'));
          this.service = new google.maps.places.PlacesService(this.map);
          this.service.getDetails(request, (placeResult, status) => {
            if (status == google.maps.places.PlacesServiceStatus.OK) {
              buissness_name = placeResult.name;
              this.placeForm = `${buissness_name}`
              place.buissness_name = buissness_name
            }
          });
        }


        // this.map = new google.maps.Map(document.createElement('div'));
        // this.service = new google.maps.places.PlacesService(this.map);


      }

      place.name = this.placeForm

      if (city) {
        try {
          let msg_tmp = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(city)}&key=AIzaSyD66m4le_x6_zZOPJiy4cWuHLIWB7bNh-g&language=iw`;
          const response = await axios.get(`${msg_tmp}`);
          if (response.data.results[0]) {
            place.city = response.data.results[0];
            place.city.geometry = response.data.results[0].geometry.location;
            place.city.name = city;
          } else {
            console.error('Google Geocoding API returned no results');
          }
        } catch (error) {
          console.error('Error querying the Google Geocoding API: ', error);
        }
      }

      this.$emit('input', { fieldName: this.fieldName, value: place, id:this.showOptions.id  });
    },
    async loadGoogleMapsScript() {
      //add a delay and repeat the delay until the script is loaded, try no more than 10 times
      let delay = 10;
      let repeat = 20;
      let counter = 0;
      while (typeof google === 'undefined' && counter < repeat) {
        await new Promise(resolve => setTimeout(resolve, delay));
        counter++;
      }
      //if google is still undefined, then the script didn't load and console log it
      if (typeof google === 'undefined') {
        console.error('Google Maps JavaScript API library is not loaded');
        return;
      }
      this.map = new google.maps.Map(document.createElement('div'));
      this.service = new google.maps.places.PlacesService(this.map);
    },
    setResuln() {
      if (this.result) {
        this.handlePlaceChanged(this.result);
      }
    }
  },
  watch: {
    clearFormFlag() {
      console.log('clearFormFlag');
      this.value = null;
      this.handlePlaceChanged(null);
    }
  }
}
</script>

<style scoped>
::v-deep .gmap-autocomplete-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.pac-target-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.google-input-r {
  width: 200px;
  outline: none;
  border: none;
  box-shadow: none;
  margin: 3px 10px 3px 10px;
}

.position-relative {
  position: relative;
}

.required-star {
  position: absolute;
  top: 0px;
  /* Настройте эти значения в соответствии с вашим дизайном */
  right: -10px;
  color: red;
  font-size: large;
  /* Или другой размер, который вам нужен */
}
</style>

  