import firebase from 'firebase/compat/app';

export default {
    state: {
        userInfo: null,
    },
    mutations: {
        setUserInfo(state, payload) {
            console.log(payload);
            state.userInfo = payload
        },
        clearUserInfo(state) {
            state.userInfo = null
            state.user = null
        }
    },
    actions: {
        async getUserInfo(context) {
            const uid = await context.dispatch('getUID');
            const info = (await firebase.database().ref(`/users/${uid}/info`).once('value')).val()
            context.commit('setUserInfo', info)
        },
        async updateUserInfo(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}`).child('info').update({
                name: payload.name || "",
                userRole: payload.userRole || "",
                bussinesNumber: payload.bussinesNumber || "",
                secondEmail: payload.secondEmail || "",
                phoneNumber: payload.phoneNumber || "",
                showContactPersons: payload.showContactPersons || false,
                showCars: payload.showCars || false
            })
            await context.dispatch('getUserInfo')
        },
        refreshUserInfo(context) {
            return context.getters.userInfo
        }
    },
    getters: {
        userInfo(state) {
            return state.userInfo || null
        },
    }
}