<template>
    <div v-if="loaded">
        <span class="text-h6">{{ label }}</span>
        <span>{{ name }}</span>
        <span v-if="userInfo.companyInfo && userInfo.companyInfo.companyName && userInfo.companyInfo.companyName != ''">
            <v-icon :color="iconColor" class="mx-3 pb-1" @click="openDialog">mdi-information</v-icon>
        </span>
        <v-dialog v-model="dialogVisible" max-width="60vw">
            <v-card class="mx-auto">
                <v-card-title>
                    <span class="headline">פרטים:</span>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="goToCompanyInfoPage">עמוד המפרסם</v-btn>
                </v-card-title>
                <v-card-text>
                    <div v-if="userInfo.companyInfo">
                        <div v-if="userInfo.companyInfo.companyName && userInfo.companyInfo.companyName != ''">
                            <span class="text-h6">שם החברה: </span>
                            <span>{{ userInfo.companyInfo.companyName }}</span>
                        </div>
                        <div v-if="userInfo.companyInfo.companyType && userInfo.companyInfo.companyType != ''">
                            <span class="text-h6">סוג החברה: </span>
                            <span>{{ userInfo.companyInfo.companyType }}</span>
                        </div>
                        <div v-if="userInfo.companyInfo.email && userInfo.companyInfo.email != ''">
                            <span class="text-h6">אימייל: </span>
                            <span>{{ userInfo.companyInfo.email }}</span>
                        </div>
                        <div v-if="userInfo.companyInfo.phone && userInfo.companyInfo.phone != ''">
                            <span class="text-h6">טלפון: </span>
                            <span>{{ userInfo.companyInfo.phone }}</span>
                        </div>
                        <div v-if="userInfo.companyInfo.webPage && userInfo.companyInfo.webPage != ''">
                            <span class="text-h6">אתר אינטרנט: </span>
                            <span>{{ userInfo.companyInfo.webPage }}</span>
                        </div>
                        <div v-if="userInfo.companyInfo.phisicalAdress && userInfo.companyInfo.phisicalAdress != ''">
                            <span class="text-h6">כתובת: </span>
                            <span>{{ userInfo.companyInfo.phisicalAdress }}</span>
                        </div>
                        <div
                            v-if="userInfo.companyInfo.registrationDate && userInfo.companyInfo.registrationDate != ''">
                            <span class="text-h6">תאריך רישום: </span>
                            <span>{{ userInfo.companyInfo.registrationDate }}</span>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="userInfo.info.name && userInfo.info.name != ''">
                            <span class="text-h6">שם: </span>
                            <span>{{ userInfo.info.name }}</span>
                        </div>
                        <div v-if="userInfo.info.email && userInfo.info.email != ''">
                            <span class="text-h6">אימייל: </span>
                            <span>{{ userInfo.info.email }}</span>
                        </div>
                        <div v-if="userInfo.info.phoneNumber && userInfo.info.phoneNumber != ''">
                            <span class="text-h6">טלפון: </span>
                            <span>{{ userInfo.info.phoneNumber }}</span>
                        </div>
                        <div v-if="userInfo.info.createdAt && userInfo.info.createdAt != ''">
                            <span class="text-h6">תאריך רישום: </span>
                            <span>{{ formatDateToDayView(userInfo.info.createdAt) }}</span>
                        </div>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="mt-3" v-if="reviews.length > 0">
                        <v-divider class="my-3"></v-divider>
                        <h2>ביקורות</h2>
                        <v-list>
                            <v-list-item v-for="(review, index) in reviews" :key="index">
                                <v-list-item-content>
                                    <v-list-item-subtitle class="text--primary">{{ review.fromName
                                        }}:</v-list-item-subtitle>
                                    <v-list-item-title>
                                        <v-rating v-model="review.rating" readonly></v-rating>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>{{ review.comment }}</v-list-item-subtitle>
                                    <v-list-item-subtitle> תאריך פרסום הביקורת: {{ formatDateToDayView(review.createdAt)
                                        }}</v-list-item-subtitle>
                                    <v-divider v-if="index < reviews.length - 1" :key="index"></v-divider>
                                </v-list-item-content>

                            </v-list-item>

                        </v-list>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <FeedbackDialog :uid="uid"></FeedbackDialog>
                    <v-spacer></v-spacer>
                    <v-btn dark color="red" @click="dialogVisible = false">סגור</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import fire from "@/main";
import FeedbackDialog from "./FeedbackDialog.vue";

export default {
    props: {
        uid: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: "הצעה מטעם:"
        },
        iconColor: {
            type: String,
            required: false,
            default: "primary"
        },
    },
    data() {
        return {
            userIngo: {},
            dialogVisible: false,
            loaded: false,
            reviews: [],
        };
    },
    components: {
        FeedbackDialog,
    },
    computed: {
        name() {
            if (this.userInfo.companyInfo && this.userInfo.companyInfo.companyName && this.userInfo.companyInfo.companyName != '') {
                return "חברת \"" + this.userInfo.companyInfo.companyName + "\"";
            } else if (this.userInfo.info && this.userInfo.info.name) {
                return this.userInfo.info.name;
            } else {
                return 'אין שם';
            }
        },
    },
    mounted() {
        this.getUserInfo();
        this.getUserReviews();
    },
    methods: {
        openDialog() {
            this.dialogVisible = true;
        },
        async getUserInfo() {
            let viewMessage = this;
            let itemRef = await fire.database().ref('users/' + this.uid);
            itemRef.on('value', (snapshot) => {
                viewMessage.userInfo = snapshot.val();
                viewMessage.loaded = true;
            });
        },
        async getUserReviews() {
            let viewMessage = this;
            let reviewsRef = await fire.database().ref('reviews').orderByChild('uid').equalTo(this.uid);
            reviewsRef.on('value', (snapshot) => {
                let reviews = [];
                snapshot.forEach((childSnapshot) => {
                    reviews.push(childSnapshot.val());
                });
                viewMessage.reviews = reviews;
            });
        },
        formatDateToDayView(date) {
            if (date) {
                let dateObj = new Date(date);
                //add 0 before day if it is less than 10
                let day = dateObj.getDate();
                if (day < 10) {
                    day = "0" + day;
                }
                //add 0 before month if it is less than 10
                let month = dateObj.getMonth() + 1;
                if (month < 10) {
                    month = "0" + month;
                }
                let year = dateObj.getFullYear();
                return day + "-" + month + "-" + year;
            }
            return "";
        },
        goToCompanyInfoPage() {
            this.$router.push({ name: 'companyInfoPageDynamic', params: { id: this.uid } });
        },
    },

};
</script>

<style scoped>
/* Your component's CSS styles go here */
</style>