<template>
    <div>
        <v-btn v-if="haveButton" class="mx-2" color="primary" elevation="0" @click="openNewPostDialog()">פרסם מודעה
            <v-icon right>mdi-plus</v-icon>
        </v-btn>
        <v-dialog persistent v-model="newPostDialog" max-width="950px">
            <div>
                <v-card text>
                    <v-card-title class="primary mb-9">
                        <span style="color: white">
                            הוסף מודעה
                        </span>
                        <v-spacer></v-spacer>
                        <v-icon class="mx-3" v-if="selectedPostTypeId !== null" color="white" @click="goBack">
                            mdi-arrow-right
                        </v-icon>
                        <v-btn icon @click="closeNewPostDialog()">
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <!-- <v-select v-if="isPostTypes" v-model="selectedPostTypeId" @change="loadFieldsForPostType"
                    :items="postTypesOptions" label="בחר סוג מודעה" return-object></v-select> -->
                    <!-- lets show all postTypesOptions as rectangles with icon and name, use flex-wrap -->

                    <div class="d-flex flex-wrap justify-center" v-if="isPostTypes && selectedPostTypeId === null">
                        <v-card flat tile v-for="(postType, index) in postTypesOptions" :key="index"
                            class="mx-5 my-5 primary"
                            :style="'max-width: 80vw; background-color: #e0e0e0; width:160px; height:160px'"
                            @click="selectPostType(postType)">
                            <v-card-title><span style="color: white">{{ postType.text }}</span></v-card-title>
                            <v-card-text class="d-flex align-center justify-center">
                                <v-icon :style="{ 'font-size': '70px', 'color': 'white' }">mdi-{{ postType.icon
                                    }}</v-icon>
                            </v-card-text>
                            <!-- <v-card-actions>
                            <v-btn @click="selectedPostTypeId = postType; loadFieldsForPostType()">בחר</v-btn>
                        </v-card-actions> -->
                        </v-card>
                    </div>




                    <div max-width="600px" v-if="fieldsExtended.length > 0 && selectedPostTypeId !== null">
                        <v-card-text flat tile>
                            <div class="d-flex flex-wrap" v-if="fieldsExtended_primary.length > 0">
                                <div v-for="field in fieldsExtended_primary" :key="field.name" class="mx-7 my-0 py-0">
                                    <v-tooltip bottom
                                        v-if="field.showOptions && field.showOptions.info && field.showOptions.info !== ''">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-bind="attrs" v-on="on">
                                                <component :is="field.type" @input="setValue(field.name, $event)"
                                                    :clearFormFlag="clearFormFlag" :fieldName="field.name"
                                                    :fields="field.fields" :showOptions="field.showOptions">
                                                </component>
                                            </div>
                                        </template>
                                        <span>{{ field.showOptions.info }}</span>
                                    </v-tooltip>
                                    <component v-else :is="field.type" @input="setValue(field.name, $event)"
                                        :clearFormFlag="clearFormFlag" :fieldName="field.name" :fields="field.fields"
                                        :showOptions="field.showOptions">
                                    </component>
                                </div>
                            </div>
                            <div v-if="fieldsExtended_regular.length > 0" class="d-flex flex-wrap">
                                <div v-for="field in fieldsExtended_regular" :key="field.name" class="mx-7 my-0 py-0">
                                    <v-tooltip bottom
                                        v-if="field.showOptions && field.showOptions.info && field.showOptions.info !== ''">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-bind="attrs" v-on="on">
                                                <component :is="field.type" @input="setValue(field.name, $event)"
                                                    :clearFormFlag="clearFormFlag" :fieldName="field.name"
                                                    :fields="field.fields" :showOptions="field.showOptions">
                                                </component>
                                            </div>
                                        </template>
                                        <span>{{ field.showOptions.info }}</span>
                                    </v-tooltip>
                                    <component v-else :is="field.type" @input="setValue(field.name, $event)"
                                        :clearFormFlag="clearFormFlag" :fieldName="field.name" :fields="field.fields"
                                        :showOptions="field.showOptions">
                                    </component>
                                </div>
                            </div>
                            <v-divider v-if="fieldsExtended_regular.length > 0" class="my-5"></v-divider>
                            <div class="d-flex flex-wrap">
                                <div v-for="field in fieldsExtended_secondary" :key="field.name" class="mx-7 my-0 py-0">
                                    <v-tooltip bottom
                                        v-if="field.showOptions && field.showOptions.info && field.showOptions.info !== ''">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-bind="attrs" v-on="on">
                                                <component :is="field.type" @input="setValue(field.name, $event)"
                                                    :clearFormFlag="clearFormFlag" :fieldName="field.name"
                                                    :fields="field.fields" :showOptions="field.showOptions">
                                                </component>
                                            </div>
                                        </template>
                                        <span>{{ field.showOptions.info }}</span>
                                    </v-tooltip>
                                    <component v-else :is="field.type" @input="setValue(field.name, $event)"
                                        :clearFormFlag="clearFormFlag" :fieldName="field.name" :fields="field.fields"
                                        :showOptions="field.showOptions">
                                    </component>
                                </div>
                            </div>
                            <v-divider v-if="fieldsExtended_secondary.length > 0" class="my-5"></v-divider>
                            <div class="d-flex flex-wrap" v-if="fieldsExtended_price.length > 0">
                                <div v-for="field in fieldsExtended_price" :key="field.name" class="mx-7 my-0 py-0">
                                    <v-tooltip bottom
                                        v-if="field.showOptions && field.showOptions.info && field.showOptions.info !== ''">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-bind="attrs" v-on="on">
                                                <component :is="field.type" @input="setValue(field.name, $event)"
                                                    :clearFormFlag="clearFormFlag" :fieldName="field.name"
                                                    :fields="field.fields" :showOptions="field.showOptions">
                                                </component>
                                            </div>
                                        </template>
                                        <span>{{ field.showOptions.info }}</span>
                                    </v-tooltip>
                                    <component v-else :is="field.type" @input="setValue(field.name, $event)"
                                        :clearFormFlag="clearFormFlag" :fieldName="field.name" :fields="field.fields"
                                        :showOptions="field.showOptions">
                                    </component>
                                </div>
                            </div>
                            <v-divider v-if="fieldsExtended_price.length > 0" class="my-5"></v-divider>
                            <v-select v-if="fieldsExtended.length > 0" v-model="values.contactPerson" :items="userContactPersons"
                                item-text="name" item-value="id" label="איש קשר" return-object>
                            </v-select>
                        </v-card-text>
                    </div>
                    <v-card-actions v-if="selectedPostTypeId !== null">
                        <v-btn @click="addPost">הוסף מודעה</v-btn>
                        <v-btn @click="clearForm">נקה טופס</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import PlaceInput from "./components/PlaceInput";
import NameInput from "./components/NameInput";
import DateRangeInput from "./components/DateRangeInput";
import CheckBoxInput from "./components/CheckBoxInput";
import SelectOneInput from "./components/SelectOneInput";
import SelectMultipleInput from "./components/SelectMultipleInput";
import SelectTwoLevelInput from "./components/SelectTwoLevelInput";
import NumberInput from "./components/NumberInput";
import fire from "@/main";

export default {
    components: {
        PlaceInput,
        NameInput,
        DateRangeInput,
        CheckBoxInput,
        SelectOneInput,
        SelectMultipleInput,
        SelectTwoLevelInput,
        NumberInput
    },
    props: {
        haveButton: {
            type: Boolean,
            default: true
        },
        openDialog: {
            type: Boolean,
            default: false
        },
        startPostType: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            allFields: [],
            fields: [],
            fieldsExtended: [],
            postTypes: [],
            selectedPostTypeId: null,
            clearFormFlag: false,
            values: {},
            newPostDialog: false,
            spacialFieldsNames: ["newPostName", "dateRange", "placeFrom", "placeTo"], //delete this
            fieldsWeights: ["regular", "primary", "secondary", "price"],
            userContactPersons: [],
        };
    },
    computed: {
        ...mapGetters(["user", "UID"]),
        isPostTypes() {
            return this.postTypes.length > 0;
        },
        postTypesOptions() {
            return this.postTypes.map(postType => ({
                text: postType.name,
                value: postType.id,
                icon: postType.icon
            }));
        },
        fieldsExtended_primary() {
            let ret = this.fieldsExtended.filter(field => field.showOptions.weight === "primary");
            if (ret.length > 0) {
                ret.sort((a, b) => a.showOptions.order - b.showOptions.order);
                return ret;
            }
            return [];
        },
        fieldsExtended_secondary() {
            let ret = this.fieldsExtended.filter(field => field.showOptions.weight === "secondary");
            if (ret.length > 0) {
                ret.sort((a, b) => a.showOptions.order - b.showOptions.order);
                return ret;
            }
            return [];
        },
        fieldsExtended_price() {
            let ret = this.fieldsExtended.filter(field => field.showOptions.weight === "price");
            if (ret.length > 0) {
                ret.sort((a, b) => a.showOptions.order - b.showOptions.order);
                return ret;
            }
            return [];
        },
        fieldsExtended_regular() {
            let ret = this.fieldsExtended.filter(field => field.showOptions.weight === "regular");
            if (ret.length > 0) {
                ret.sort((a, b) => a.showOptions.order - b.showOptions.order);
                return ret;
            }
            return [];
        },

    },
    async mounted() {
        await this.initFunc();
        if (this.openDialog) {
            this.newPostDialog = true;
        }
        if (this.startPostType) {
            this.selectedPostTypeId = this.startPostType;
            this.selectPostType(this.startPostType)
        }
        // console.log("user", this.user);
        console.log("UID", this.UID);
    },
    methods: {
        loadFieldsForPostType() {
            console.log('Selected Post Type ID:', this.selectedPostTypeId);
            if (this.selectedPostTypeId) {
                const postType = this.postTypes.find(pt => pt.id === this.selectedPostTypeId.value);
                if (postType && postType.fieldIds) {
                    console.log("allFields", this.allFields);
                    this.fields = this.allFields.filter(field => postType.fieldIds.includes(field.id));
                }
                console.log('Loaded Fields for Selected Post Type:', this.fields);
                if (postType && postType.postTypeExtended) {
                    this.fieldsExtended = this.allFields.filter(field => {
                        for (let i = 0; i < postType.postTypeExtended.length; i++) {
                            if (postType.postTypeExtended[i].id === field.id) {
                                field.showOptions = postType.postTypeExtended[i];
                                return true;
                            }
                        }
                    }
                    );
                }
                console.log('Loaded Fields Extended for Selected Post Type:', this.fieldsExtended);

            } else {
                this.fields = [];
            }
        },
        setValue(componentName, value) {
            // this.$set(this.values, componentName, value.value);
            this.$set(this.values, value.id, { id: value.id, key: componentName, value: value.value });
            console.log("componentName", componentName);
            console.log("value", value);
            console.log("this.values", this.values);
        },
        clearForm() {
            this.clearFormFlag = true;
            this.values = {};
            setTimeout(() => {
                this.clearFormFlag = false;
                this.goBack();
                this.newPostDialog = false;
            }, 0);
        },
        async addPost() {
            this.newPostDialog = false;
            console.log("values", this.values);
            console.log("this.fieldsExtended", this.fieldsExtended);
            let allRequiredFields = this.fieldsExtended.filter(field => field.showOptions.required);
            console.log("allRequiredFields", allRequiredFields);
            // in value, the key is the name of the field (the one that is in this.fieldsExtended[some id].name), and the value is the value of the field
            // so we need to check if all required fields are in values
            let allRequiredFieldsAreFilled = true;
            let notFilledRequiredFieldsNames = [];
            for (let i = 0; i < allRequiredFields.length; i++) {
                if (!this.values[allRequiredFields[i].id]) {
                    allRequiredFieldsAreFilled = false;
                    console.log("allRequiredFields[i].name is not filled", allRequiredFields[i].name);
                    notFilledRequiredFieldsNames.push(allRequiredFields[i].name);
                }
            }
            if (!allRequiredFieldsAreFilled) {
                alert("אחד או מספר שדות החובה לא מולאו. נא למלא את כל השדות המסומנים ב-* /n" + notFilledRequiredFieldsNames.join(", "));
                return;
            }
            // if (!this.values || Object.keys(this.values).length === 0) {
            //     alert("אחד או מספר שדות החובה לא מולאו. נא למלא את כל השדות המסומנים ב-* /n"+notFilledRequiredFieldsNames.join(", "));
            //     return;
            // }
            console.log("write to db...");
            await fire.database().ref("admin/posts/")
                .push({
                    postTypeId: this.selectedPostTypeId.value,
                    values: this.values,
                    created: new Date().toISOString(),
                    lastUpdated: new Date().toISOString(),
                    uid: this.UID,
                    status: "active",
                    contactPerson: this.values.contactPerson.id
                });
            setTimeout(() => {
                this.clearForm();
            }, 0);
        },
        goBack() {
            this.selectedPostTypeId = null;
            this.fields = [];
        },
        selectPostType(postType) {
            console.log("selectPostType", postType);
            this.selectedPostTypeId = postType;
            this.loadFieldsForPostType();
        },
        async initFunc() {
            //get user contact persons
            await fire.database().ref('users/' + this.UID + '/contactPersons').once('value', async (snapshot) => {
                const rawData = snapshot.val();
                // console.log("rawData", rawData);
                this.userContactPersons = Object.keys(rawData).map(key => {
                    return {
                        id: rawData[key].existUID          // добавляем id
                    };
                });
                //add, as a first object, the user himself
                this.userContactPersons.unshift({ id: this.UID });
                //get contact persons name and add it to the object for each contact person
                for (let i = 0; i < this.userContactPersons.length; i++) {
                    // console.log("this.userContactPersons[i].id", this.userContactPersons[i].id);
                    if (this.userContactPersons[i].id === this.UID) {
                        this.userContactPersons[i].name = "אני";
                        continue;
                    }
                    await fire.database().ref('users/' + this.userContactPersons[i].id + '/info/name').once('value', snapshot => {
                        console.log("snapshot.val()", snapshot.val());
                        this.userContactPersons[i].name = snapshot.val();
                    });
                }
                console.log("userContactPersons", this.userContactPersons);
            });
            await fire.database().ref('admin/fields/created_fields/addRecord').once('value', snapshot => {
                const rawData = snapshot.val();
                this.allFields = Object.keys(rawData).map(key => {
                    return {
                        ...rawData[key], // все свойства объекта
                        id: key          // добавляем id
                    };
                });
                // console.log("allFields", this.allFields);
            });


            await fire.database().ref('admin/postTypes/').once('value', snapshot => {
                this.postTypes = Object.values(snapshot.val());
                console.log("postTypes", this.postTypes);
            });
        },
        async openNewPostDialog() {
            await this.initFunc();
            this.newPostDialog = true;
        },
        closeNewPostDialog() {
            this.newPostDialog = false;
            this.$emit("closeNewPostDialog");
        }
    },
};
</script>
