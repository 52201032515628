<template>
    <div>
        <div>
            <component :is="fieldValues.type.type" :fieldName="fieldValues.name" :val="fieldValues.value"
                :type="fieldValues.type"></component>
        </div>
    </div>
</template>

<script>

import PlaceInput from "@/components/showRecord/components//PlaceInput";
import NameInput from "@/components/showRecord/components/NameInput";
import DateRangeInput from "@/components/showRecord/components/DateRangeInput";
import CheckBoxInput from "@/components/showRecord/components/CheckBoxInput";
import SelectOneInput from "@/components/showRecord/components/SelectOneInput";
import SelectMultipleInput from "@/components/showRecord/components/SelectMultipleInput";
import SelectTwoLevelInput from "@/components/showRecord/components/SelectTwoLevelInput";
import NumberInput from "@/components/showRecord/components/NumberInput";
export default {
    props: {
        fieldValues: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
        };
    },
    components: {
        PlaceInput,
        NameInput,
        DateRangeInput,
        CheckBoxInput,
        SelectOneInput,
        SelectMultipleInput,
        SelectTwoLevelInput,
        NumberInput
    },
    mounted() {
        // console.log("fieldValues", this.fieldValues);
    }
};
</script>