<template>
    <div>
        <span class="text-h6" >{{ type.fields.label.value }}:</span><span> {{ value }}</span>
    </div>
</template>
<script>
export default {
    props: ["val", "type"],
    data() {
        return {
            value: "false",
        };
    },
    mounted() {
        // console.log(this.val);
        this.value = this.val;
    }
}

</script>