<template>
  <v-card class="mx-9 my-3 rounded-xl" dense>
    <v-card-text>
      <v-checkbox :input-value="checked" :indeterminate="checked === null" @click="toggleCheckbox"
        :label="`${val.fields.label.value}`"></v-checkbox>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["val", "type"],
  data() {
    return {
      checked: null  // Нейтральное состояние
    };
  },
  watch: {
    checked() {
      this.emitFilter();
    }
  },
  methods: {
    toggleCheckbox() {
      // Циклическое переключение между true, false и null
      if (this.checked === null) {
        this.checked = true;
      } else if (this.checked === true) {
        this.checked = false;
      } else {
        this.checked = null;
      }

      this.emitFilter();
    },
    emitFilter() {
      this.$emit('update-filter', post => {
        if (this.checked === null) return true;  // Всегда возвращает true если checked нейтрально

        let postValue = post.values[`${this.val.name}`];
        postValue = postValue === 'true' ? true : postValue === 'false' ? false : postValue;  // Преобразование строк "true" и "false" в булевые значения

        // Возвращаем true, если значения совпадают, или если postValue не определено и checked равно false
        console.log(this.checked, post.values[`${this.val.name}`]);
        return this.checked === postValue || (postValue === undefined && this.checked === false);
      });
    }
  }
}
</script>
  