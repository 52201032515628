import firebase from 'firebase/compat/app';

export default {
    state: {
        contactPersons: null,
        // foreignContactPer
    },
    mutations: {
        setContactPersons(state, payload) {
            state.contactPersons = payload
        },
    },
    actions: {
        async getContactPersons(context) {
            const uid = await context.dispatch('getUID');
            const info = (await firebase.database().ref(`/users/${uid}/contactPersons`).once('value')).val() || null
            let info_arr = null
            if (info) {
                info_arr = Object.keys(info).map(key => ({ ...info[key], id: key }))
                for (let i = 0; i < info_arr.length; i++) {
                    if (info_arr[i].existUID && info_arr[i].existUID !== "") {
                        // console.log("existUID", info_arr[i].existUID);
                        let existUSER = await context.dispatch("getUserByID", { uid: info_arr[i].existUID })
                        info_arr[i] = {
                            ...info_arr[i], ...existUSER, role: existUSER.userRole, phone: existUSER.phoneNumber
                        }
                        // one_contact = {...one_contact,...existUSER}
                        // context.dispatch("")

                    }
                }

            }
            context.commit('setContactPersons', info_arr)
            return info
        },
        async getContactPersonsByID(context, payload) {
            const uid = payload;
            const info = (await firebase.database().ref(`/users/${uid}/contactPersons`).once('value')).val()
            return info
        },
        async getContactPersonByUserByID(context, payload) {
            const info = (await firebase.database().ref(`/users/${payload.uid}/contactPersons/${payload.id}`).once('value')).val()
            return info
        },
        async updateContactPerson(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}/contactPersons`).child(`${payload.id}`).update({
                name: payload.name || '',
                role: payload.role || '',
                phone: payload.phone || '',
                email: payload.email || '',
                existUID: payload.existUID || ''
            })
            await context.dispatch('getContactPersons')
        },
        async AddContactPerson(context, payload) {
            if (!payload.existUID || payload.existUID === "") {
                const uid = await context.dispatch('getUID');
                await firebase.database().ref(`users/${uid}/contactPersons`).push({
                    name: payload.name || '',
                    role: payload.role || '',
                    phone: payload.phone || '',
                    email: payload.email || ''
                })
            } else {
                const uid = await context.dispatch('getUID');
                // let existUSER = await context.dispatch("getUserByID", { uid: payload.existUID })
                await firebase.database().ref(`users/${uid}/contactPersons`).push({
                    existUID: payload.existUID || ''
                })
                await firebase.database().ref(`users/${payload.existUID}`).child(`info`).update({
                    workerOf: uid
                })
            }
            await context.dispatch('getContactPersons')
        },
        async removeContactPerson(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}/contactPersons/${payload}`).remove()
            await context.dispatch('getContactPersons')
        },
        refreshContactPersons(context) {
            return context.getters.contactPersons
        },
        contactPerson(context, payload) {
            // let oneContactPerson = context.getters.contactPersons[payload]
            let oneContactPerson = context.getters.contactPersons.find(c => {
                if (c.id === payload) {
                    return c
                }
            })
            return oneContactPerson
        },
    },
    getters: {
        contactPersons(state) {
            return state.contactPersons || null
        },
    }
}