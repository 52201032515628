<template>
    <v-menu :disabled="!enable" offset-y :close-on-content-click="false" transition="scale-transition" v-model="dialog"
        max-width="290">
        <template v-slot:activator="{ on }">
            <v-col cols="3" class="my-0 py-0" align-self="center"
                style="display: flex; align-items: center; justify-content: center">
                <v-icon v-for="(actions, index) in updatedActions" :key="index" :disabled="!enable" @click="dialog = true"
                    v-on="on" :color="iconColor(actions)">mdi-{{ actions.icon }}</v-icon>
            </v-col>
        </template>
        <v-card>
            <v-card-text>
                <v-row v-for="(actions, index) in updatedActions" :key="index" class="my-0 py-0">
                    <v-col class="my-0 py-0">
                        <v-checkbox dense @click="submit" v-model="actions.checked" :label="actions.label"></v-checkbox>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-menu>
</template>
  
<style scoped>
.min-row {
    /* height: 36px; */
}
</style>
  
<script>
export default {
    name: "oneFurnitureRowNodal",
    props: {
        labels: {
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        enable: {
            type: String,
            required: false,
        },
        result: {
            default: [],
        },
    },
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
        updatedActions() {
            if (this.labels.actions && Array.isArray(this.labels.actions)) {
                return this.labels.actions.map(action => ({
                    ...action,
                    //if result is not empty, find the action in result and set checked to the checked value in result, do it by label
                    checked: this.result.find(result => result.label === action.label)
                        ? this.result.find(result => result.label === action.label).checked
                        : false,

                    // checked: false,
                }));
            }
            return [];
        }
    },
    methods: {
        submit() {
            this.$emit("submit", this.updatedActions);
            //   this.dialog = false;
        },
        iconColor(action) {
            return action.checked ? "green" : "grey";
        }
    },
};
</script>
  
  