<template>
  <div class="mx-1 my-0 py-0">
    <v-card flat width="230px">
      <v-text-field color="deep-orange lighten-2" dense outlined :value="internalValue" @input="updateValue"
        :label="fields.label.value"></v-text-field>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "NameInput",
  props: ["clearFormFlag", "fieldName", "fields", "result", "showOptions"],
  data() {
    return {
      internalValue: "",
      label: "שם",  // временно статическое значение, пока мы не загружаем его из базы данных
    };
  },
  watch: {
    clearFormFlag(newVal) {
      if (newVal) {
        this.internalValue = "";
      }
    },
  },
  mounted() {
    // this.internalValue = this.result ? this.result : "";
    if (this.result !== undefined) {
      this.updateValue(this.result);
    }
  },
  created() {
    console.log("NameInput CREATED");
  },
  methods: {
    updateValue(newValue) {
      this.internalValue = newValue;
      this.$emit("input", { fieldName: this.fieldName, value: newValue, id:this.showOptions.id  });
    },
  },
};
</script>
