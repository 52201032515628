<template>
  <div class="mx-3 my-0 py-0">
    <v-card flat width="230px">
      <v-select :items="options" :label="val.fields.label.value" v-model="selectedValues" multiple clearable dense
        outlined no-data-text="Нет данных"></v-select>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["val", "type"],
  data() {
    return {
      selectedValues: [],
      options: this.val.fields.options ? this.val.fields.options.value.split(',') : []
    };
  },
  watch: {
    selectedValues() {
      this.emitFilter();
    }
  },
  methods: {
    emitFilter() {
      this.$emit('update-filter', post => {
        // Если ничего не выбрано, возвращаем true (не фильтруем посты)
        if (!this.selectedValues.length) return true;
        if (!post.values[`${this.val.id}`]) return false;
        // Проверяем, содержит ли массив в посте хотя бы одно из выбранных значений
        const postValues = post.values[`${this.val.id}`].value;
        if (Array.isArray(postValues)) {
          return this.selectedValues.some(val => postValues.includes(val));
        } else {
          // Если значение не массив, проверяем, содержится ли оно среди выбранных
          return this.selectedValues.includes(postValues);
        }
      });
    }
  }
}
</script>
