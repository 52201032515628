import firebase from 'firebase/compat/app';

export default {
    state: {
        usersInfo: [],
        usersFullInfo: []
    },
    mutations: {
        usersInfo(state, payload) {
            state.usersInfo = payload
        },
        userInfoByID(state, payload) {
            state.usersInfo.push(payload)
        },
        usersFullInfo(state, payload) {
            state.usersFullInfo.push(payload)
        },
    },
    actions: {
        async getUserByID(context, payload) {
            try {
                let userByID = null;
                let userExist = null
                if (!payload.uid) {
                    console.error("no payload.uid");
                    throw 'no user'
                } else {
                    userExist = await context.getters.usersInfo.find(v => {
                        if (v.uid) {
                            if (v.uid === payload.uid) return v
                        }
                    })
                }
                if (userExist) {
                    userByID = userExist
                } else {
                    userByID = (await firebase.database().ref(`users/${payload.uid}/info`).once('value')).val()
                    if (!userByID) {
                        throw 'no user'
                    }
                    // console.warn(userByID)
                    await context.commit('userInfoByID', userByID)
                }
                return userByID
            } catch (error) {
                context.dispatch('clearError')
                context.dispatch('setError', error.message)
                throw error
            }

        },
        async getUserFullByID(context, payload) {
            try {
                let userByID = null;
                let userExist = null
                if (!payload.uid) {
                    console.error("no payload.uid");
                    throw 'no user'
                } else {
                    if (context.getters.usersFullInfo) {
                        userExist = await context.getters.usersFullInfo.find(v => {
                            if (v.info.uid) {
                                if (v.info.uid === payload.uid) return v
                            }
                        })
                    }
                }
                if (userExist) {
                    userByID = userExist
                } else {
                    userByID = (await firebase.database().ref(`users/${payload.uid}`).once('value')).val()
                    if (!userByID) {
                        throw 'no user'
                    }
                    // console.warn(userByID)
                    await context.commit('usersFullInfo', userByID)
                }
                return userByID
            } catch (error) {
                context.dispatch('clearError')
                context.dispatch('setError', error.message)
                throw error
            }

        },
    },
    getters: {
        usersInfo(state) {
            return state.usersInfo
        },
        userInfoByID(state, payload) {
            return state.usersInfo[payload]
        },
        usersFullInfo(state, payload) {
            return state.usersFullInfo[payload]
        },
    }
}