<template>
  <v-app>
    <Navbar />
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer padless class="transparent mt-16">
      <v-container>
        <v-row>
          <v-col class="" cols="12">
            <v-img position="right" src="@/assets/logo_go_color.png" contain max-height="34"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="" cols="4">
            <span>מידע חיוני</span>
          </v-col>
          <v-col class="" cols="4">
            <div><span class="caption mx-0">עלינו</span></div>
            <div><span class="caption mx-0">שיתוף פעולה</span></div>
            <div><span class="caption mx-0">בלוג</span></div>
            <div><span class="caption mx-0">צור קשר</span></div>
          </v-col>
          <v-col class="" cols="4">
            <div><span class="caption">mail@mail.com</span></div>
            <div><span class="caption">053-2776648</span></div>
            <div><span class="caption">go24.co.il</span></div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <template v-if="error">
      <v-snackbar :value="error" v-model="snackbarError" :multi-line="true" :timeout="5000" @input="closeError">
        {{ error }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="closeError">
            סגור
          </v-btn>
        </template>
      </v-snackbar>
    </template>
    <template v-if="seccess">
      <v-snackbar :value="seccess" v-model="snackbarSuccess" :multi-line="true" :timeout="5000" @input="closeSuccess">
        {{ seccess }}

        <template v-slot:action="{ attrs }">
          <v-btn color="green" text v-bind="attrs" @click="closeSuccess">
            סגור
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
  data: () => ({
    snackbarError: false,
    snackbarSuccess: false,
  }),
  computed: {
    error() {
      return this.$store.getters.error;
    },
    seccess() {
      return this.$store.getters.success;
    },
  },
  methods: {
    closeError() {
      console.log("closeError");
      this.$store.dispatch("clearError");
      this.snackbarError = false;
    },
    closeSuccess() {
      console.log("closeSuccess");
      this.$store.dispatch("clearSuccess");
      this.snackbarSuccess = false;
    },
  },
  watch: {
    error(newValue) {
      if(newValue) {
        console.log("error"+newValue);
        this.snackbarError = true;
      }
    },
    seccess(newValue) {
      if(newValue) {
        console.log("seccess"+newValue);
        this.snackbarSuccess = true;
      }
    },
  },
};
</script>
