<template>
  <div>
    <v-subheader class="mt-3">{{ this.fields.title.value }}</v-subheader>
    <div v-if="resultLoaded">
      <v-card flat v-for="(FurnitureRow, index) in allFurnitureRows" :key="FurnitureRow.index">
        <oneFurnitureRow :index="FurnitureRow.index" :currentIndex="index" :allFurnitureRows="allFurnitureRows"
          @remove="remove" :singleElement="allFurnitureRows.length === 1" @update:modelValue="updated" :furnitures="items"
          :actions="computedActions" :label_1="fields.label_1.value" :label_2="fields.label_2.value"
          :result="resultToPass(FurnitureRow) ">
        </oneFurnitureRow>
      </v-card>

      <v-btn class="my-3" color="primary" :disabled="allFurnitureRows.length >= 5 ? true : false" @click="add">להוסיף
        רהיט</v-btn>
    </div>
  </div>
</template>

<script>

import oneFurnitureRow from "./oneFurnitureRow.vue";

export default {
  name: "SelectTwoLevelInput",
  props: ["clearFormFlag", "fieldName", "fields", "result", "showOptions"],
  data() {
    return {
      // internalValue: [],
      allFurnitureRows: null,
      allFurnitureRowsIndex: 0,
      resultLoaded: false,
    };
  },
  components: {
    oneFurnitureRow,
  },
  computed: {
    items() {
      return JSON.parse(this.fields.options.value);
    },
    computedActions() {
      return JSON.parse(this.fields.actions.value);
    },
  },
  mounted() {
    if (this.result) {
      console.log("this.result in SelectTwoLevels: ", this.result);
      this.allFurnitureRows = [{ index: 0 }];
      this.allFurnitureRowsIndex = 1;
      if (this.result[0].value) {
        this.result[0].value.currentIndex = this.allFurnitureRowsIndex-1;
        this.updated(this.result[0].value);
      }
      if (this.result.length > 1) {
        for (let i = 1; i < this.result.length; i++) {
          if (this.result[i].value) {
            this.add();
            this.result[i].value.currentIndex = this.allFurnitureRowsIndex-1;
            this.updated(this.result[i].value);
          } else {
            console.log("NO VALUE");
          }
        }
      }

      // this.allFurnitureRows = this.result;
      // this.allFurnitureRowsIndex = this.allFurnitureRows.length;
      this.resultLoaded = true;

    } else {
      this.allFurnitureRows = [{ index: 0 }];
      this.allFurnitureRowsIndex = 1;
      this.resultLoaded = true;
    }
    console.log("this.allFurnitureRows", this.allFurnitureRows);
    console.log("this.allFurnitureRowsIndex", this.allFurnitureRowsIndex);
  },
  watch: {
    clearFormFlag(newVal) {
      if (newVal) {
        // this.internalValue = [];
      }
    },
  },
  methods: {
    add() {
      this.allFurnitureRows.push({ index: this.allFurnitureRowsIndex++ })
      console.log("add");
    },
    remove(indexToRemove) {
      this.allFurnitureRows.forEach((item, index) => {
        if (item.index === indexToRemove) {
          this.allFurnitureRows.splice(index, 1);
        }
      });
      this.$emit('input', { fieldName: this.fieldName, value: this.allFurnitureRows, id:this.showOptions.id  });
    },
    updated(v) {
      console.log("updated", v);
      this.allFurnitureRows[v.currentIndex].value = v
      delete this.allFurnitureRows[v.currentIndex].value.currentIndex
      // console.log("allFurnitureRows", this.allFurnitureRows);
      this.$emit('input', { fieldName: this.fieldName, value: this.allFurnitureRows, id:this.showOptions.id  });
      this.emitInput();
    },
    emitInput() {


    },
    getTypeByIndex(index) {
      if (this.allFurnitureRows[index] && this.allFurnitureRows[index].value) {
        if (this.allFurnitureRows[index].value.selectedFurnituresType) {
          return this.allFurnitureRows[index].value.selectedFurnituresType;
        }
      }
      return null;
    },
    getItemByIndex(index) {
      // console.log("this.allFurnitureRows[index]", this.allFurnitureRows[index]);
      if (this.allFurnitureRows[index] && this.allFurnitureRows[index].value) {
        if (this.allFurnitureRows[index].value.selectedFurniture) {
          return this.allFurnitureRows[index].value.selectedFurniture;
        }
      }
      return null;
    },
    getItemsByIndex(index) {
      if (this.allFurnitureRows[index] && this.allFurnitureRows[index].value) {
        if (this.allFurnitureRows[index].value.items) {
          // console.log("this.allFurnitureRows[index].value.items", this.allFurnitureRows[index].value.items);
          return this.allFurnitureRows[index].value.items;
        }
      }
      return null;
    },
    getActionsByIndex(index) {
      if (this.allFurnitureRows[index] && this.allFurnitureRows[index].value) {
        if (this.allFurnitureRows[index].value.actionsNeeded) {
          // console.log("this.allFurnitureRows[index].value.items", this.allFurnitureRows[index].value.items);
          return this.allFurnitureRows[index].value.actionsNeeded;
        }
      }
      return null;
    },
    resultToPass(FurnitureRow) {
      // console.log("FurnitureRow!!!", FurnitureRow);
      if (!FurnitureRow.value) {
        console.log("FurnitureRow.value!!!", FurnitureRow.value);
        return null;
      }
      let res = {
        type: this.getTypeByIndex(FurnitureRow.index),
        item: this.getItemByIndex(FurnitureRow.index),
        items: this.getItemsByIndex(FurnitureRow.index),
        actionsNeeded: this.getActionsByIndex(FurnitureRow.index),
      }
      return res;
    },
  },
};
</script>
